import { Box, Fade, Stack } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ThemingManagerProfileExample } from '@vp/manager/theming/ui/example/ThemingManagerProfileExample';
import { ThemeManagerViewModel } from '@vp/manager/theming/ui/ThemeManagerViewModel';
import { ThemingManagerControls } from '@vp/manager/theming/ui/ThemingManagerControls';
import { ThemingManagerPageHeader } from '@vp/manager/theming/ui/ThemingManagerPageHeader';
import { ThemingManagerSettings } from '@vp/manager/theming/ui/ThemingManagerSettings';
import { ProfileThemeProvider } from '@vp/profile/ui/ProfileThemeProvider';

export const ThemingManagerPage: FC = () => {
  const viewModel = useViewModel(ThemeManagerViewModel);

  return (
    <VpFade>
      <Stack width={1} height="100dvh">
        <ThemingManagerPageHeader />

        <Stack width={1} flexGrow={1} overflow="scroll" gap={1} px={{ mobile: 3, tablet: 7 }}>
          <ThemingManagerSettings theme={viewModel.theme.value} switchTheme={viewModel.switchTheme} />

          <ProfileThemeProvider theme={viewModel.theme.value}>
            <ThemingManagerProfileExample />
          </ProfileThemeProvider>
        </Stack>

        <Fade in={viewModel.dirty.value} timeout={250} easing="ease-in-out">
          <Box position="fixed" width={{ mobile: 1, desktop: '53%' }} bottom={0}>
            <ThemingManagerControls loading={viewModel.loading.value} onSave={viewModel.save} onCancel={viewModel.cancel} />
          </Box>
        </Fade>
      </Stack>
    </VpFade>
  );
};

ThemingManagerPage.displayName = ThemingManagerPage.name;
