import { Box, Stack } from '@mui/material';
import { FC, Fragment, useCallback, useState } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { NoRecordsMessage } from '@vp/profile/ui/honor/NoRecordsMessage';
import { ProfileHonorRecords } from '@vp/profile/ui/honor/ProfileHonorRecords';
import { RecordSubmitNotification } from '@vp/profile/ui/honor/RecordSubmitNotification';
import { SubmitRecordModal } from '@vp/profile/ui/honor/SubmitRecordModal';
import { useSectionMinHeight } from '@vp/profile/ui/hook/useSectionMinHeight';

interface ProfileHonorProps {
  profile: ProfileModel;
}

export type ProfileHonorNotificationStatus = 'success' | 'error' | 'info';

export const ProfileHonor: FC<ProfileHonorProps> = ({ profile }) => {
  const [showSubmitRecordModal, setShowSubmitRecordModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState<ProfileHonorNotificationStatus>('info');

  const minHeight = useSectionMinHeight();

  const openSubmitRecordModal = useCallback(() => {
    setShowSubmitRecordModal(true);
  }, []);

  const closeSubmitRecordModal = useCallback(() => {
    setShowSubmitRecordModal(false);
  }, []);

  const handleSubmissionResult = useCallback(
    (status: ProfileHonorNotificationStatus) => {
      if (status !== 'info') {
        // TODO use new notification approach
        setNotificationStatus(status);
        setShowNotification(true);
      }

      if (status === 'success') {
        closeSubmitRecordModal();
      }
    },
    [closeSubmitRecordModal],
  );

  const closeRecordSubmissionNotification = useCallback(() => setShowNotification(false), []);

  return (
    <Fragment>
      <Stack minHeight={minHeight} px={{ mobile: 3, tablet: 7 }} pt={{ mobile: 2, tablet: 3 }} alignItems="center">
        {profile.honorRecords.length > 0 ? <ProfileHonorRecords records={profile.honorRecords} /> : <NoRecordsMessage />}
        <Box
          sx={{
            width: 1,
            position: 'sticky',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'background.300',
            py: { mobile: 2, tablet: 4 },
          }}
        >
          <VpFancyButton text="Оставить запись" onClick={openSubmitRecordModal}></VpFancyButton>
        </Box>
      </Stack>

      <SubmitRecordModal
        profileId={profile.id}
        open={showSubmitRecordModal}
        handleSubmissionResult={handleSubmissionResult}
        close={closeSubmitRecordModal}
      />

      <RecordSubmitNotification open={showNotification} status={notificationStatus} close={closeRecordSubmissionNotification} />
    </Fragment>
  );
};
