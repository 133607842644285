import { Box, Fade, Modal, useTheme } from '@mui/material';
import { ModalOwnProps } from '@mui/material/Modal/Modal';
import { FC, useCallback } from 'react';

import { SubmitRecordContent } from '@vp/honor/ui/components/SubmitRecordContent';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface SubmitRecordModalProps {
  open: boolean;
  profileId: string;
  close: (status?: ProfileHonorNotificationStatus) => void;
  handleSubmissionResult: (status: ProfileHonorNotificationStatus) => void;
}

export const SubmitRecordModal: FC<SubmitRecordModalProps> = ({ profileId, open, close, handleSubmissionResult }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  const onClose = useCallback<NonNullable<ModalOwnProps['onClose']>>((_, reason) => reason === 'escapeKeyDown' && close(), [close]);

  return (
    <Modal open={open} aria-modal hideBackdrop onClose={onClose}>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box
          width={1}
          height={1}
          autoFocus
          tabIndex={0}
          position="relative"
          sx={({ palette }) => ({ overflowY: 'scroll', outline: 'none', backgroundColor: palette.background.default })}
        >
          <SubmitRecordContent
            profileId={profileId}
            close={close}
            handleSubmissionResult={handleSubmissionResult}
            submitButtonText="Отправить на модерацию"
          />
        </Box>
      </Fade>
    </Modal>
  );
};
