import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormDescriptionProps {
  disabled: boolean;
}

export const BiographyManagerFormDescription: FC<BiographyManagerFormDescriptionProps> = ({ disabled }) => {
  const { register, formState } = useFormContext<BiographyFormValues>();

  return (
    <VpTextField
      multiline
      fullWidth
      id="form-text"
      label="Описание"
      disabled={disabled}
      error={!!formState.errors?.biography}
      slotProps={{
        multilineInput: {
          placeholder:
            'Напишите биографию человека, включая ключевые моменты его жизни, достижения и наследие, которое он оставил для будущих поколений.',
          registerReturn: register('biography', {
            required: true,
            minLength: 4,
            maxLength: 10000,
          }),
        },
      }}
    />
  );
};
