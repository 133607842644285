import { FC } from 'react';

import { AuthPageContainer } from '@vp/auth/ui/AuthPageContainer';
import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { ForgotPasswordForm } from '@vp/auth/ui/forgot-password/ForgotPasswordForm';

export const ForgotPasswordPage: FC = () => {
  return (
    <AuthPageContainer>
      <AuthPageDescription
        title="Восстановление Доступа"
        description="Введите свой адрес электронной почты, и мы отправим вам ссылку для сброса пароля."
      />

      <ForgotPasswordForm />
    </AuthPageContainer>
  );
};
