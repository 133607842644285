import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';

import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export interface ProfileSettingsNavigationProps {
  profile: ProfileModel;
}

export const ProfileSettingsInfo: FC<ProfileSettingsNavigationProps> = ({ profile }) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [marginTop, setMarginTop] = useState(0);
  const [top, setTop] = useState(0);

  const { customSizes, breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  const name = profile.name || 'Имя не указано';
  const dates = profile.birthDate && profile.deathDate ? `${profile.birthDate} - ${profile.deathDate}` : '';

  useRectOnResize(
    containerRef,
    useCallback(
      ({ height }) => {
        if (!isDesktop) {
          setMarginTop(-(height + customSizes.profileSettingsMenuOffset.mobile));
          setTop(customSizes.smallImageHeight.mobile - height - customSizes.profileSettingsMenuOffset.mobile);
        } else {
          setMarginTop(0);
          setTop(0);
        }
      },
      [customSizes.profileSettingsMenuOffset.mobile, customSizes.smallImageHeight.mobile, isDesktop],
    ),
  );

  return (
    <Box ref={containerRef} position="sticky" style={{ marginTop: `${marginTop}px`, top: `${top}px` }}>
      <Stack pb={3} px={{ mobile: 3, tablet: 7 }} width={1} textAlign="center">
        <Typography variant="h3" color="text.secondary">
          {name}
        </Typography>

        <Typography variant="caption2Regular" color="text.disabled">
          {dates}
        </Typography>
      </Stack>
    </Box>
  );
};
