import { CssBaseline, ThemeProvider } from '@mui/material';
import { Fragment, JSX } from 'react';

import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { AppMenu } from '@vp/menu/ui/AppMenu';
import { AppNotificationOutlet } from '@vp/notification/AppNotificationOutlet';

import { AppRouter } from './AppRouter';
import { AppViewModel } from './AppViewModel';
import { theme } from './theme/theme';

export const App = (): JSX.Element => {
  const viewModel = useViewModel(AppViewModel);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {viewModel.initialized.value && (
        <Fragment>
          <AppMenu />
          <AppRouter />
          <AppNotificationOutlet />
        </Fragment>
      )}
    </ThemeProvider>
  );
};
