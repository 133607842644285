import { Box } from '@mui/material';
import { FC } from 'react';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

export type ProfileDesktopImage = {
  image: ProfilePhotoModel;
};

export const ProfileDesktopImage: FC<ProfileDesktopImage> = ({ image }) => {
  return (
    <Box sx={{ width: 1, height: '100dvh', overflow: 'hidden' }}>
      <VpImage src={image.url} hash={image.hash} />
      <Box
        sx={{
          inset: 0,
          position: 'absolute',
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.2) 100%)',
        }}
      />
    </Box>
  );
};
