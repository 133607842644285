import { Box, Fade, Modal, Theme, useTheme } from '@mui/material';
import { ModalOwnProps } from '@mui/material/Modal/Modal';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, useCallback } from 'react';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpModalProps extends PropsWithChildren {
  open: boolean;
  sx?: SxProps<Theme>;
  close?: () => void;
  maxWidth?: number | string;
}

export const VpModal: FC<VpModalProps> = ({ open, close, sx, maxWidth, children }) => {
  const { transitions, palette } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  const onClose = useCallback<NonNullable<ModalOwnProps['onClose']>>((_, reason) => reason === 'escapeKeyDown' && close?.(), [close]);

  return (
    <Modal
      aria-modal
      open={open}
      onClose={onClose}
      slotProps={{ backdrop: { sx: { backdropFilter: 'blur(10px)', backgroundColor: 'transparent' } } }}
    >
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box width={1} height={1} px={2} display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={theme => ({
              width: 1,
              maxWidth: maxWidth,
              backgroundColor: palette.background[200],
              borderRadius: 1.5,
              ...resolveSx(sx, theme),
            })}
          >
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
