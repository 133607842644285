import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import Logo from '@vp/assets/icons/Logo.svg?react';
import QrCodeLarge from '@vp/assets/icons/QrCodeLarge.svg?react';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpModal } from '@vp/common/ui/component/VpModal';

export type ProfileAdModalProps = {
  open: boolean;
  close: () => void;
};

const Text = 'Установите мемориальный\nблистер с QR-кодом в память\nо ваших близких.';

const ButtonGradient =
  'linear-gradient(90.82deg, rgba(229, 229, 229, 0.35) -43.91%, rgba(229, 229, 229, 0.15) 48.8%, rgba(229, 229, 229, 0) 150.27%)';

export const ProfileAdModal: FC<ProfileAdModalProps> = ({ open, close }) => {
  return (
    <VpModal sx={{ maxWidth: 440 }} open={open} close={close}>
      <Stack width={1} py={3} alignItems="center" position="relative">
        <VpIconButton Icon={Close} sx={{ position: 'absolute', top: 0, right: 0, p: 1, color: 'text.disabled' }} onClick={close} />

        <Typography mx={3} mb={2} variant="h4" color="text.secondary" align="center">
          Сохраните память навсегда!
        </Typography>

        <Typography variant="body1Regular" color="text.disabled" align="center" whiteSpace="pre-line" mb={1}>
          {Text}
        </Typography>

        <Box width={200} height={200} display="flex" justifyContent="center" alignItems="center" position="relative" mb={2}>
          <VpIcon
            Icon={QrCodeLarge}
            size={145}
            sx={{ maskImage: `radial-gradient(circle, transparent 42px, black 42px)`, color: 'text.primary' }}
          />

          <VpIcon
            Icon={Logo}
            size={68}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'text.primary',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 175,
              height: 162,
              border: '0.75px solid',
              borderColor: 'text.primary',
              '&::before': {
                content: '""',
                position: 'absolute',
                border: '0.75px solid',
                borderColor: 'text.primary',
                inset: '-6px 6px',
              },
            }}
          />
        </Box>

        <VpFancyButton
          text="Хочу запечатлеть Впамяти"
          {...{ component: 'a', href: 'https://впамяти.рф/catalog', target: '_blank' }}
          sx={{ background: ButtonGradient }}
        />
      </Stack>
    </VpModal>
  );
};
