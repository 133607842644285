import { Box } from '@mui/material';
import { FC } from 'react';

export const ProfilePageNoise: FC = () => {
  return (
    <Box
      sx={theme => ({
        ...theme.applyStyles('light', {
          inset: 0,
          position: 'fixed',
          pointerEvents: 'none',
          backgroundImage: theme.lightNoise,
          zIndex: theme.zIndex.galleryProfileInfo + 1,
        }),
      })}
    />
  );
};
