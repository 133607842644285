import { Box, Stack } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { memo, useCallback, useEffect, useState } from 'react';

import ChevronLeft from '@vp/assets/icons/ChevronLeft.svg?react';
import ChevronRight from '@vp/assets/icons/ChevronRight.svg?react';
import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

export type ProfileImageCarouselProps = {
  selectedPhotoId: string;
  photos: ProfilePhotoModel[];
  close: () => void;
};

const Gradient = 'linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.25) 100%)';

export const ProfileImageCarousel = memo<ProfileImageCarouselProps>(({ close, photos, selectedPhotoId }) => {
  const [index, setIndex] = useState(() => photos.findIndex(({ id }) => selectedPhotoId === id));
  const photo = photos[index];

  const getNext = useCallback((direction: number) => (index + direction + photos.length) % photos.length, [index, photos.length]);

  const onLeft = useCallback(() => setIndex(getNext(-1)), [getNext]);
  const onRight = useCallback(() => setIndex(getNext(1)), [getNext]);

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        onLeft();
      } else if (event.key === 'ArrowRight') {
        onRight();
      }
    };

    window.addEventListener('keydown', onKeydown);

    return (): void => window.removeEventListener('keydown', onKeydown);
  });

  return (
    <Box
      autoFocus
      tabIndex={0}
      sx={({ palette }) => ({
        position: 'relative',
        width: 1,
        height: 1,
        overflow: 'hidden',
        outline: 'none',
        backgroundColor: palette.background.default,
      })}
    >
      <VpMediaGradientOverlay sx={{ width: 1, height: 1 }} gradient={Gradient}>
        {photo && (
          <AnimatePresence mode="wait">
            <VpFade key={photo.id} duration={0.25} sx={{ width: 1, height: 1 }}>
              <VpImage src={photo.url} hash={photo.hash} mode="contain" animateAppearance={false} />
            </VpFade>
          </AnimatePresence>
        )}
      </VpMediaGradientOverlay>

      <CarouselControls onLeft={onLeft} onRight={onRight} />

      <VpIconButton
        Icon={CloseIcon}
        sx={theme => ({
          p: 1,
          position: 'fixed',
          zIndex: theme.zIndex.mediaControls,
          top: { mobile: 0, tablet: theme.spacing(1) },
          left: { mobile: 0, tablet: theme.spacing(3) },
        })}
        onClick={close}
      />
    </Box>
  );
});

ProfileImageCarousel.displayName = 'ProfileImageCarousel';

type CarouselControlsProps = {
  onLeft(): void;
  onRight(): void;
};

const CarouselControls = memo<CarouselControlsProps>(({ onLeft, onRight }) => {
  return (
    <Stack
      sx={{
        px: 5,
        left: 0,
        width: 1,
        top: '50%',
        zIndex: 1,
        position: 'fixed',
        flexDirection: 'row',
        transform: 'translateY(-50%)',
        justifyContent: 'space-between',
      }}
    >
      <VpIconButton
        Icon={ChevronLeft}
        sx={{ p: 2, borderRadius: '50%', backgroundColor: 'transparency.alpha16', backdropFilter: 'blur(10px)' }}
        onClick={onLeft}
      />
      <VpIconButton
        Icon={ChevronRight}
        sx={{ p: 2, borderRadius: '50%', backgroundColor: 'transparency.alpha16', backdropFilter: 'blur(10px)' }}
        onClick={onRight}
      />
    </Stack>
  );
});

CarouselControls.displayName = 'CarouselControls';
