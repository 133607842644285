import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { CSSProperties, forwardRef, useCallback, useMemo, useState } from 'react';
import { Blurhash } from 'react-blurhash';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpImageProps {
  src: string;
  hash?: string;
  width?: string;
  height?: string;
  alt?: string;
  position?: string;
  sx?: SxProps<Theme>;
  mode?: 'cover' | 'contain';
  animateAppearance?: boolean;
  imageProps?: { sx?: SxProps<Theme>; style?: CSSProperties };
}

export const VpImage = forwardRef<HTMLElement, VpImageProps>(
  ({ src, hash, width, height, alt, position, sx, mode = 'cover', animateAppearance = true, imageProps }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const onLoad = useCallback(() => setLoaded(true), []);
    const modeStyles = useMemo(() => resolveMode(mode), [mode]);
    const imageStyles = useMemo(() => resolveStyle(imageProps?.style), [imageProps]);

    const transition = animateAppearance ? 'opacity 0.5s ease-in-out' : undefined;
    const opacity = Number(loaded);
    position ??= 'center';
    hash ||= 'L5EV+:0000of00-;xu%M~qt7xuD%';

    return (
      <Box ref={ref} sx={theme => ({ position: 'relative', ...resolveSx(sx, theme) })} width={width ?? '100%'} height={height ?? '100%'}>
        <Blurhash hash={hash} width="100%" height="100%" />

        <Box
          component={'img'}
          alt={alt ?? 'image'}
          src={src}
          draggable="false"
          style={{ opacity, objectPosition: position, transition, ...imageStyles }}
          sx={theme => ({ position: 'absolute', top: '50%', left: '50%', ...modeStyles, ...resolveSx(imageProps?.sx, theme) })}
          onLoad={onLoad}
        />
      </Box>
    );
  },
);

VpImage.displayName = 'VpImage';

function resolveMode(mode: NonNullable<VpImageProps['mode']>): SystemStyleObject {
  if (mode === 'contain') {
    return { maxWidth: '100%', width: '100%', maxHeight: '100%', objectFit: 'contain' };
  }

  return { width: '100%', height: '100%', objectFit: 'cover' };
}

function resolveStyle(style?: CSSProperties): CSSProperties {
  const values = ['translate(-50%, -50%)', style?.transform];
  const transform = values.filter(Boolean).join(' ');
  return { ...style, transform };
}
