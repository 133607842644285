import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeft from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export const AvatarManagerHeader: FC = () => {
  return (
    <VpHeader
      sx={{
        position: { mobile: 'sticky', tablet: 'fixed' },
        width: { mobile: 1, desktop: '53%' },
        pb: 1,
        px: { tablet: 4 },
        pt: { tablet: 2 },
        backgroundColor: 'background.300',
      }}
    >
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeft} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 1 } })} to=".." />
      </VpHeaderColumn>
      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary" textAlign="center">
          Фото профиля
        </Typography>
      </VpHeaderColumn>
      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
