import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { RouterService } from '@vp/routing/RouterService';
import { Unsubscribe } from '@vp/routing/RouterSubscribers';

@injectable()
export class ManagerGatewayViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  readonly hasError: Signal<boolean> = signal(false);
  readonly initialized: Signal<boolean> = signal(false);
  readonly profile: ReadonlySignal<ProfileModel | null> = computed(() => this.toViewProfile());

  private unsubscribe?: Unsubscribe;

  constructor(
    private readonly routerService: RouterService,
    private readonly profileManagerState: ProfileManagerState,
    private readonly managerPort: ProfileManagerPort,
    private readonly dateFormatter: DateFormatter,
  ) {
    super();
  }

  init(): void {
    this.unsubscribe = this.routerService.subscribe(() => {
      if (this.routerService.isOn('/ps/:id?/*')) {
        this.handleRouteChange();
      }
    });
  }

  dispose(): void {
    this.unsubscribe?.();
  }

  private handleRouteChange(): void {
    const { id } = this.routerService.getParams('/ps/:id?/*');

    if (id) {
      this.makeProfileActive(id);
    } else {
      this.makeFirstProfileActive();
    }

    this.initialized.value = true;
  }

  private makeProfileActive(id: string): void {
    const profile = this.profileManagerState.getById(id);

    if (profile) {
      this.managerPort.setActiveProfile(profile);
    } else {
      this.hasError.value = true;
    }
  }

  private makeFirstProfileActive(): void {
    const profiles = this.profileManagerState.profiles.value;

    if (profiles.length > 0) {
      const first = profiles[0];
      this.managerPort.setActiveProfile(first);
      void this.routerService.navigate(`/ps/${first.id}`, { replace: true });
    }
  }

  private toViewProfile(): ProfileModel | null {
    const profile = this.profileManagerState.active.value;
    if (!profile) return null;
    const birthDate = this.dateFormatter.toLocaleDate(profile.birthDate);
    const deathDate = this.dateFormatter.toLocaleDate(profile.deathDate);
    return { ...profile, birthDate, deathDate };
  }
}
