import { Typography } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';

export interface GalleryManagerUploadHeaderProps {
  onClose: () => void;
}

export const GalleryManagerUploadHeader: FC<GalleryManagerUploadHeaderProps> = ({ onClose }) => {
  const section = useSearchSection<GalleryManagerSection>('s', GalleryManagerSection.Photos);
  const text = section === 'photos' ? 'Загрузить фото' : 'Загрузить видео';

  return (
    <VpHeader
      sx={{
        position: 'static',
        backgroundColor: 'background.300',
        px: { tablet: 4 },
        pt: { tablet: 2 },
        pb: { mobile: 0.5, tablet: 1 },
      }}
    >
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={Close} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 1 } })} onClick={onClose} />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          {text}
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
