import { Typography } from '@mui/material';
import { FC } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export interface SubmitRecordHeaderProps {
  close: () => void;
  disableCloseIcon: boolean;
}

export const SubmitRecordHeader: FC<SubmitRecordHeaderProps> = ({ close, disableCloseIcon }) => {
  return (
    <VpHeader
      sx={theme => ({ position: 'static', background: theme.palette.background[300], mb: 1, px: { tablet: 3 }, mt: { desktop: 0.25 } })}
    >
      <VpHeaderColumn align="left">
        <VpIconButton
          glowing
          disabled={disableCloseIcon}
          Icon={CloseIcon}
          onClick={close}
          sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 1 } })}
        />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          Оставить запись
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
