import { Typography } from '@mui/material';
import { FC } from 'react';

import Edit from '@vp/assets/icons/Edit.svg?react';
import Menu from '@vp/assets/icons/Menu.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export interface ProfileSettingsPageHeaderProps {
  noProfiles: boolean;
  onMenuClick: () => void;
}

export const ProfileSettingsPageHeader: FC<ProfileSettingsPageHeaderProps> = ({ noProfiles, onMenuClick }) => {
  return (
    <VpHeader
      sx={{
        width: { mobile: 1, desktop: '53%' },
        alignItems: 'center',
        justifyContent: 'space-between',
        px: { mobile: 1, tablet: 3, desktop: 5 },
        pt: { mobile: 1, desktop: 3 },
      }}
    >
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={Menu} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 2 } })} onClick={onMenuClick} />
      </VpHeaderColumn>

      <VpHeaderColumn>
        {!noProfiles && (
          <Typography variant="h6" color="text.primary" textAlign="center">
            Настройки профиля
          </Typography>
        )}
      </VpHeaderColumn>

      <VpHeaderColumn align="right">
        {!noProfiles && <VpIconButton glowing Icon={Edit} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 2 } })} to="avatar" />}
      </VpHeaderColumn>
    </VpHeader>
  );
};
