import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';
import { animate, MotionValue, motionValue } from 'motion';

import { SlideshowState } from '@vp/slideshow/core/interface/SlideshowState';

@injectable()
export class SlideshowAudioController {
  readonly muted: Signal<boolean> = signal(false);

  private audio!: HTMLAudioElement | null;
  private context?: AudioContext;
  private source?: MediaElementAudioSourceNode;
  private gainNode?: GainNode;

  private volume!: MotionValue<number>;
  private smoothedVolume!: MotionValue<number>;

  private readonly defaultVolume: number = 0.25;
  private readonly reducedVolume: number = 0.1;
  private readonly animationDurationInSeconds: number = 1;

  private currentAnimation: ReturnType<typeof animate> | null = null;

  private readonly subscriptions: Set<() => void> = new Set();

  constructor(private readonly slideshowState: SlideshowState) {}

  attach(): void {
    this.unsubscribe();
    this.initializeAudio();
    this.initializeVolume();
    this.initializeAnimation();
  }

  detach(): void {
    this.muteSmoothly();
    this.cleanup();
  }

  play(): void {
    void this.audio!.play();
    this.initializeAudioContextIfNeeded();
    this.volume.set(this.defaultVolume);
  }

  pause(): void {
    this.audio!.pause();
  }

  reduceVolume(): void {
    this.volume.set(this.reducedVolume);
  }

  toggleMuted(): void {
    this.muted.value = !this.muted.value;
    this.audio!.muted = this.muted.value;
  }

  private muteSmoothly(): void {
    this.volume.set(0);
  }

  private initializeAudio(): void {
    const url = URL.createObjectURL(this.slideshowState.audio.value);
    this.audio = new Audio(url);
    this.audio.loop = true;
  }

  private initializeVolume(): void {
    this.volume = motionValue(0);
    this.smoothedVolume = motionValue(this.volume.get());
  }

  private initializeAnimation(): void {
    this.subscriptions.add(
      this.volume.on('change', value => {
        this.currentAnimation?.stop();

        this.currentAnimation = animate(this.smoothedVolume, value, {
          duration: this.animationDurationInSeconds,
          ease: 'linear',
          onComplete: () => {
            this.currentAnimation = null;

            if (this.volume.get() === 0) {
              this.pause();
            }
          },
        });
      }),
    );

    this.subscriptions.add(
      this.smoothedVolume.on('change', value => {
        this.setVolume(value);
      }),
    );
  }

  private initializeAudioContextIfNeeded(): void {
    if (!this.context) {
      this.context = new AudioContext();
      this.source = this.context.createMediaElementSource(this.audio!);
      this.gainNode = this.context.createGain();
      this.source.connect(this.gainNode).connect(this.context.destination);
      this.setVolume(0);
    }

    if (this.context.state === 'suspended') {
      void this.context.resume();
    }
  }

  private setVolume(value: number): void {
    this.gainNode?.gain.setValueAtTime(value, this.context?.currentTime ?? 0);
  }

  private cleanup(): void {
    URL.revokeObjectURL(this.audio!.src);
  }

  private unsubscribe(): void {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }

    this.subscriptions.clear();
  }
}
