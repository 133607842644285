import { Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

interface HonorManagerEditFormProps {
  handleEdit: (id: string, newText: string) => Promise<void>;
  record: ProfileHonorRecordModel;
  isRequestInProgress: boolean;
  lockInteractions: boolean;
}

export const HonorManagerEditForm: FC<HonorManagerEditFormProps> = ({ handleEdit, record, isRequestInProgress, lockInteractions }) => {
  const { register, handleSubmit, watch, formState } = useForm<{ text: string }>({ defaultValues: { text: record.text }, mode: 'all' });

  const currentText = watch('text');
  const isSubmitDisabled = useMemo(
    () => !formState.isValid || currentText === record.text || isRequestInProgress || lockInteractions,
    [currentText, formState.isValid, isRequestInProgress, lockInteractions, record.text],
  );

  const onSubmit = useCallback(
    async (data: { text: string }) => {
      await handleEdit(record.id, data.text);
    },
    [handleEdit, record.id],
  );

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ height: 1, alignItems: 'center', justifyContent: 'space-between', px: { mobile: 3, tablet: 7 }, pb: { mobile: 2, tablet: 4 } }}
    >
      <VpTextField
        fullWidth
        multiline
        id="form-honor-edit"
        disabled={isRequestInProgress}
        error={!!formState.errors.text}
        slotProps={{
          multilineInput: {
            registerReturn: register('text', {
              required: true,
              minLength: 8,
              maxLength: 280,
            }),
            maxHeight: 200,
            placeholder: 'Например, воспоминания, слова благодарности или просто мысли об ушедшем',
          },
        }}
      />

      <VpFancyButton disabled={isSubmitDisabled} loading={isRequestInProgress} type="submit" text="Сохранить" />
    </Stack>
  );
};
