import { Box, useTheme } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { FC } from 'react';

import Add from '@vp/assets/icons/Add.svg?react';
import { VpSpinner } from '@vp/common/ui/component/spinner/VpSpinner';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface AvatarImagePlaceholder {
  uploading: boolean;
  onFileUpload: (files: File[]) => Promise<void>;
}

export const AvatarImagePlaceholder: FC<AvatarImagePlaceholder> = props => {
  const { customSizes } = useTheme();

  return (
    <Box
      sx={{
        width: 1,
        height: customSizes.fullImageHeight.mobile,
        background:
          'linear-gradient(90.82deg, rgba(229, 229, 229, 0.35) -43.91%, rgba(229, 229, 229, 0.15) 48.8%, rgba(229, 229, 229, 0) 150.27%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.2) 100%)',
        padding: 1,
      }}
    >
      <Box sx={theme => ({ border: `1px dashed ${theme.palette.transparency.alpha48}`, height: '100%', width: '100%' })}>
        <AnimatePresence mode="wait">
          <VpFade
            duration={0.25}
            key={props.uploading.toString()}
            sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {props.uploading && <VpSpinner />}

            {!props.uploading && (
              <VpIconButton
                sx={theme => ({
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  color: theme.palette.primary[500],
                })}
                size={32}
                fileUpload={{ accept: 'img', multiple: false, onFileUpload: props.onFileUpload }}
                Icon={Add}
              />
            )}
          </VpFade>
        </AnimatePresence>
      </Box>
    </Box>
  );
};
