import { Box, ButtonBase, styled, Typography, TypographyOwnProps, useTheme } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import { AnimatePresence } from 'motion/react';
import { CSSProperties, FC, Fragment, SVGProps, useMemo } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { VpSpinner } from '@vp/common/ui/component/spinner/VpSpinner';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { FileUploadProps, VpHiddenFileInput } from '@vp/common/ui/component/VpHiddenFileInput';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';

type BaseButtonProps = Parameters<typeof ButtonBase>[0];

export type VpFancyButtonProps = BaseButtonProps & {
  text: string;
  size?: 'large' | 'medium' | 'small';
  loading?: boolean;
  to?: To;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  fileUpload?: FileUploadProps;
};

const VpFancyButtonRoot = styled(Box)<Required<Pick<VpFancyButtonProps, 'size'>>>(() => ({
  display: 'inline-flex',
  variants: [
    { props: { size: 'small' }, style: { width: 184 } },
    { props: { size: 'medium' }, style: { width: 303 } },
    { props: { size: 'large' }, style: { width: 311 } },
  ],
}));

const VpFancyButtonBase = styled(ButtonBase)<BaseButtonProps & Required<Pick<VpFancyButtonProps, 'size'>>>(({ theme }) => ({
  width: '100%',
  position: 'relative',
  willChange: 'opacity',
  margin: `${theme.spacing(0.875)} 0`,
  '&::after': getGlow(theme),
  transition: `opacity 0.25s ease-in-out`,
  '&.Mui-disabled': { opacity: 0.32 },
  '&::before': { content: '""', position: 'absolute', inset: `-2px 0`, ...getBorder(theme) },
  variants: [
    { props: { size: 'small' }, style: { padding: `${theme.spacing(1.25)} ${theme.spacing(2)}` } },
    { props: { size: 'medium' }, style: { padding: `${theme.spacing(1.625)} ${theme.spacing(2.5)}` } },
    { props: { size: 'large' }, style: { padding: `${theme.spacing(2)} ${theme.spacing(3)}` } },
  ],
}));

const VpFancyButtonText = styled(Typography)<TypographyOwnProps & Required<Pick<VpFancyButtonProps, 'size'>>>(({ theme }) => ({
  color: theme.palette.primary[500],
  variants: [
    { props: { size: 'small' }, style: theme.typography.buttonMedium },
    { props: { size: 'medium' }, style: theme.typography.buttonLarge },
    { props: { size: 'large' }, style: theme.typography.buttonGiant },
  ],
}));

const SizeToNumber = {
  small: 20,
  medium: 22,
  large: 24,
};

export const VpFancyButton: FC<VpFancyButtonProps> = ({ text, size = 'medium', Icon, loading = false, sx, fileUpload, ...props }) => {
  const theme = useTheme();
  const delayedLoading = useFalsyStateDelay(loading, 250);
  const fadeKey = `${delayedLoading},${text}`;

  const border = useMemo(() => getBorder(theme), [theme]);
  const glow = useMemo(() => getGlow(theme), [theme]);

  return (
    <VpFancyButtonRoot size={size}>
      <VpFancyButtonBase
        size={size}
        sx={sx}
        {...props}
        {...(delayedLoading ? { disabled: true } : {})}
        LinkComponent={props.to ? RouterLink : props.LinkComponent}
        {...(fileUpload ? { component: 'label' } : {})}
      >
        <AnimatePresence mode="wait">
          <VpFade key={fadeKey} duration={0.25} style={{ height: `${SizeToNumber[size]}px`, display: 'flex' }}>
            {!delayedLoading && (
              <Fragment>
                {Icon && <VpIcon Icon={Icon} size={24} sx={{ mr: 1.25 }} />}

                <VpFancyButtonText size={size} noWrap>
                  {text}
                </VpFancyButtonText>
              </Fragment>
            )}

            {delayedLoading && <VpSpinner size={SizeToNumber[size]} />}
          </VpFade>
        </AnimatePresence>

        <Box sx={{ ...border, position: 'absolute', inset: '-8px 6px', '&::before': glow }} />
        {fileUpload && <VpHiddenFileInput {...fileUpload} />}
      </VpFancyButtonBase>
    </VpFancyButtonRoot>
  );
};

function getBorder(theme: Theme): CSSProperties {
  const color = theme.palette.primary[500] as string;
  const gradient = `linear-gradient(93.17deg, ${alpha(color, 0.7)} 1.37%, ${alpha(color, 0.5)} 50.9%, ${alpha(color, 0.1)} 105.12%)`;

  return {
    boxSizing: 'border-box',
    border: '2px solid',
    borderImageSlice: 1,
    borderImageSource: gradient,
  };
}

function getGlow(theme: Theme): CSSProperties {
  if (theme.palette.mode === 'light') {
    return {};
  }

  return {
    ...getBorder(theme),
    content: '""',
    inset: -2,
    zIndex: -1,
    position: 'absolute',
    filter: 'blur(10px)',
  };
}
