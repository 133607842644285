import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

export interface ProfileHonorRecordProps {
  record: ProfileHonorRecordModel;
}

export const ProfileHonorRecord: FC<ProfileHonorRecordProps> = ({ record }) => {
  return (
    <Stack>
      <Stack alignItems={'flex-end'} direction={'row'}>
        <Typography flexShrink={1} variant="body2Bold" color="text.secondary" sx={{ transition: 'color 0.25s ease-in-out' }} noWrap>
          {record.author}
        </Typography>
        <Typography px={0.5} variant="caption1Regular" color="text.disabled" sx={{ transition: 'color 0.25s ease-in-out' }}>
          •
        </Typography>
        <Typography
          flexShrink={0}
          noWrap
          flexGrow={1}
          variant="caption1Regular"
          color="text.disabled"
          sx={{ transition: 'color 0.25s ease-in-out' }}
        >
          {record.date}
        </Typography>
      </Stack>

      <Typography
        paddingTop={0.5}
        variant="body2Regular"
        color="text.disabled"
        whiteSpace="pre-wrap"
        sx={{ wordBreak: 'break-word', transition: 'color 0.25s ease-in-out' }}
      >
        {record.text}
      </Typography>
    </Stack>
  );
};
