import { Box } from '@mui/material';
import { FC } from 'react';

import { HonorManagerTabs } from '@vp/manager/honor/ui/component/header/HonorManagerTabs';
import { HonorManagerTopBar } from '@vp/manager/honor/ui/component/header/HonorManagerTopBar';

interface HonorManagerHeaderProps {
  showSelectButton: boolean;
  disableInteractions: boolean;
}

export const HonorManagerHeader: FC<HonorManagerHeaderProps> = ({ showSelectButton, disableInteractions }) => {
  return (
    <Box
      sx={({ palette }) => ({
        position: 'sticky',
        top: 0,
        backgroundColor: palette.background.default,
        zIndex: 1,
        px: { tablet: 4 },
        pt: { tablet: 2 },
        pb: { mobile: 2, tablet: 3 },
      })}
    >
      <HonorManagerTopBar disableInteractions={disableInteractions} showSelectButton={showSelectButton} />
      <HonorManagerTabs disableInteractions={disableInteractions} />
    </Box>
  );
};
