import { InternalStandardProps as StandardProps } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';
import { styled, SxProps, Theme } from '@mui/system';
import { forwardRef, HTMLAttributes, MutableRefObject, ReactNode, useCallback, useRef } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

export type VpTextInputProps = {
  id?: string;
  variant?: 'large' | 'medium' | 'small' | 'x-small';
  status?: 'success' | 'danger';
  type?: 'text' | 'email' | 'password';
  disabled?: boolean;
  placeholder?: string;
  endAdornment?: ReactNode;
  registerReturn?: UseFormRegisterReturn;
  sx?: SxProps<Theme>;
} & StandardProps<HTMLAttributes<HTMLInputElement>>;

const VpTextInputRoot = styled('div', { name: 'VpTextInput' })<Pick<VpTextInputProps, 'variant' | 'disabled' | 'status'>>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette.transparency.alpha16,
  transition: 'border-color 0.25s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.transparency.alpha48,
  },
  '&:focus-within': {
    borderColor: theme.palette.primary[500],
  },
  '--vp-text-input-end-adornment-color': theme.palette.text.disabled,
  variants: [
    {
      props: { variant: 'x-small' },
      style: {
        padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
        gap: theme.spacing(0.75),
        '--vp-text-input-end-adornment-size': '18px',
      },
    },
    {
      props: { variant: 'small' },
      style: {
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)} ${theme.spacing(1.75)}`,
        gap: theme.spacing(1),
        '--vp-text-input-end-adornment-size': '20px',
      },
    },
    {
      props: { variant: 'medium' },
      style: {
        padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(2)}`,
        gap: theme.spacing(1.25),
        '--vp-text-input-end-adornment-size': '22px',
      },
    },
    {
      props: { variant: 'large' },
      style: {
        padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`,
        gap: theme.spacing(1.5),
        '--vp-text-input-end-adornment-size': '24px',
      },
    },

    {
      props: { disabled: true },
      style: {
        opacity: 0.32,
      },
    },
    {
      props: { status: 'danger' },
      style: {
        borderColor: theme.palette.danger[500],
        '--vp-text-input-end-adornment-color': theme.palette.danger[500],
      },
    },
    {
      props: { status: 'success' },
      style: {
        borderColor: theme.palette.success[500],
        '--vp-text-input-end-adornment-color': theme.palette.success[500],
      },
    },
  ],
}));

const VpTextInputRaw = styled('input', { name: 'VpTextInput', slot: 'input' })<Omit<VpTextInputProps, 'endAdornment'>>(({ theme }) => ({
  width: '100%',
  minWidth: 0,
  outline: 'none',
  border: 'none',
  padding: 0,
  background: 'transparent',
  color: theme.palette.text.primary,
  WebkitTapHighlightColor: 'transparent',
  '&:active': {
    backgroundColor: 'initial',
  },
  '&::placeholder': {
    color: theme.palette.transparency.alpha48,
  },
  '&:-webkit-autofill': {
    WebkitBoxShadow: 'none',
    WebkitTextFillColor: theme.palette.primary['500'],
    backgroundClip: 'text',
  },
  variants: [
    {
      props: { variant: 'x-small' },
      style: {
        ...(theme.typography as Typography)?.formPlaceholderXSmall,
      },
    },
    {
      props: { variant: 'small' },
      style: {
        ...(theme.typography as Typography)?.formPlaceholderSmall,
      },
    },
    {
      props: { variant: 'medium' },
      style: {
        ...(theme.typography as Typography)?.formPlaceholderMedium,
      },
    },
    {
      props: { variant: 'large' },
      style: {
        ...(theme.typography as Typography)?.formPlaceholderMedium,
      },
    },
  ],
}));

export const VpTextInput = forwardRef<HTMLInputElement, VpTextInputProps>((props, ref) => {
  const { id, variant = 'large', disabled, status, endAdornment, type = 'text', registerReturn, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const onClick = useCallback(() => inputRef.current?.focus(), []);

  const setRef = useCallback(
    (element: HTMLInputElement) => {
      inputRef.current = element;

      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }

      registerReturn?.ref(element);
    },
    [ref, registerReturn],
  );

  return (
    <VpTextInputRoot variant={variant} disabled={disabled} status={status} onClick={onClick}>
      <VpTextInputRaw
        {...configureInputType(type)}
        {...rest}
        id={id}
        variant={variant}
        disabled={disabled}
        {...registerReturn}
        ref={setRef}
      />
      {endAdornment}
    </VpTextInputRoot>
  );
});

VpTextInput.displayName = 'VpTextInput';

function configureInputType(type: NonNullable<VpTextInputProps['type']>): Record<string, unknown> {
  if (type !== 'email') {
    return { type };
  }

  return {
    type: 'email',
    autoComplete: 'email',
    autoCorrect: 'off',
    autoCapitalize: 'none',
    spellCheck: false,
  };
}
