import { Stack, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { FC, useMemo } from 'react';

import { useLinearGradient } from '@vp/common/ui/hook/useLinearGradient';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileEvent } from '@vp/profile/ui/events/ProfileEvent';
import { useSectionMinHeight } from '@vp/profile/ui/hook/useSectionMinHeight';

export interface ProfileEventsProps {
  profile: ProfileModel;
  top: number;
}

export const ProfileEvents: FC<ProfileEventsProps> = ({ profile, top }) => {
  const minHeight = useSectionMinHeight();
  const divider = useDivider();

  const hasEvents = profile.events.length > 0;

  return (
    <Stack
      width={1}
      minHeight={minHeight}
      px={{ mobile: 3, tablet: 7 }}
      pt={{ mobile: 2, tablet: 3 }}
      pb={{ mobile: 2, tablet: 4 }}
      gap={{ mobile: 3, tablet: 4 }}
      sx={{
        position: 'relative',
        '&::before': hasEvents ? { ...divider, filter: 'blur(10px)' } : {},
        '&::after': hasEvents ? divider : {},
      }}
    >
      {profile.events.map(event => (
        <ProfileEvent key={event.id} event={event} top={top} />
      ))}
    </Stack>
  );
};

const useDivider = (): SystemStyleObject => {
  const { spacing } = useTheme();

  const gradient = useLinearGradient({
    angle: '139.82deg',
    stops: [
      { percent: 14.02, opacity: 1 },
      { percent: 33.4, opacity: 0.2 },
      { percent: 92.5, opacity: 0 },
    ],
  });

  return useMemo(
    () => ({
      content: '""',
      position: 'absolute',
      height: `calc(100% - 32px)`,
      width: '1px',
      top: { mobile: spacing(2), tablet: spacing(3) },
      left: { mobile: spacing(16), tablet: spacing(20) },
      background: gradient,
    }),
    [gradient, spacing],
  );
};
