import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, forwardRef, Fragment, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { VpSystemThemeProvider } from '@vp/common/ui/component/VpSystemThemeProvider';
import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';
import { useImageSizes } from '@vp/profile/ui/hook/useImageSizes';
import { ProfileNavigation } from '@vp/profile/ui/navigation/ProfileNavigation';

export interface ProfileInfoProps {
  name: string;
  birthDate: string;
  deathDate: string;
}

export const ProfileInfo = memo(
  forwardRef<HTMLDivElement, ProfileInfoProps>(({ name, deathDate, birthDate }, ref) => {
    const navigationRef = useRef<HTMLDivElement>(null!);
    const [navigationTop, setNavigationTop] = useState(0);
    const [navigationHeight, setNavigationHeight] = useState(0);
    const [scrollProgress, setScrollProgress] = useState(0);
    const { palette, breakpoints } = useTheme();
    const { fullImageHeight, smallImageHeight } = useImageSizes();

    const heightDiff = fullImageHeight - smallImageHeight;
    const dates = birthDate && deathDate ? `${birthDate} - ${deathDate}` : '';
    const isDesktop = useMediaQuery(breakpoints.up('desktop'));

    useImperativeHandle(ref, () => navigationRef.current);

    useRectOnResize(
      navigationRef,
      useCallback(
        ({ height }) => {
          if (isDesktop) return setNavigationTop(0);
          setNavigationHeight(height);
          setNavigationTop(smallImageHeight - height);
        },
        [isDesktop, smallImageHeight],
      ),
    );

    useEffect(() => {
      if (isDesktop) return setScrollProgress(0);

      const root = document.getElementById('root')!;

      const onScroll = (): void => {
        const distance = Math.max(root.scrollTop - heightDiff, 0);
        const progress = Math.min(distance / navigationHeight, 1) * 100;
        setScrollProgress(progress);
      };

      onScroll();
      root.addEventListener('scroll', onScroll);

      return (): void => root.removeEventListener('scroll', onScroll);
    }, [heightDiff, isDesktop, navigationHeight]);

    return (
      <Stack
        ref={navigationRef}
        sx={theme => ({
          width: 1,
          position: 'sticky',
          zIndex: theme.zIndex.galleryProfileInfo,
          [theme.breakpoints.up('desktop')]: { backgroundColor: 'background.300' },
        })}
        style={{ top: `${navigationTop}px` }}
      >
        {(palette.mode === 'dark' || isDesktop) && <ProfileInfoContent name={name} dates={dates} />}

        {palette.mode === 'light' && !isDesktop && (
          <Fragment>
            <VpSystemThemeProvider>
              <ProfileInfoContent name={name} dates={dates} />
            </VpSystemThemeProvider>

            <Stack sx={{ position: 'absolute', inset: 0, pointerEvents: 'none' }} style={{ clipPath: `inset(${scrollProgress}% 0 0 0)` }}>
              <ProfileInfoContent name={name} dates={dates} />
            </Stack>
          </Fragment>
        )}
      </Stack>
    );
  }),
);

ProfileInfo.displayName = ProfileInfo.name;

const ProfileInfoContent: FC<{ name: string; dates: string }> = memo(({ name, dates }) => {
  return (
    <Fragment>
      <Stack pt={{ mobile: 3, desktop: 10.25 }} pb={3} px={{ mobile: 3, tablet: 7 }} width={1} textAlign="center">
        <Typography
          variant="h3"
          sx={theme => ({
            color: 'text.secondary',
            ...theme.applyStyles('light', {
              color: 'text.primary',
            }),
          })}
        >
          {name || 'Имя не указано'}
        </Typography>

        <Typography variant="caption2Regular" color="text.disabled">
          {dates}
        </Typography>
      </Stack>

      <ProfileNavigation />
    </Fragment>
  );
});

ProfileInfoContent.displayName = ProfileInfoContent.name;
