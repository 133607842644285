import { Divider, Stack } from '@mui/material';
import { FC, useCallback } from 'react';

import { BiographyManagerToggle } from '@vp/manager/biography/ui/BiographyManagerToggle';
import { AiOptionStatus } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerAIControlsProps {
  changeAiOptionStatus: (status: AiOptionStatus) => void;
  disabled: boolean;
  aiOptionStatus: AiOptionStatus;
}

export const BiographyManagerAIControls: FC<BiographyManagerAIControlsProps> = ({ changeAiOptionStatus, aiOptionStatus, disabled }) => {
  const toggleSpellingCheck = useCallback(() => {
    if (aiOptionStatus === AiOptionStatus.SpellingCheck) {
      changeAiOptionStatus(AiOptionStatus.None);
    } else {
      changeAiOptionStatus(AiOptionStatus.SpellingCheck);
    }
  }, [aiOptionStatus, changeAiOptionStatus]);

  const toggleSupplementedDescription = useCallback(() => {
    if (aiOptionStatus === AiOptionStatus.SupplementedDescription) {
      changeAiOptionStatus(AiOptionStatus.None);
    } else {
      changeAiOptionStatus(AiOptionStatus.SupplementedDescription);
    }
  }, [aiOptionStatus, changeAiOptionStatus]);

  return (
    <Stack divider={<Divider />}>
      <BiographyManagerToggle
        disabled={disabled}
        label="ИИ проверка орфографии"
        field={AiOptionStatus.SpellingCheck}
        state={aiOptionStatus === AiOptionStatus.SpellingCheck}
        onToggle={toggleSpellingCheck}
      />
      <BiographyManagerToggle
        disabled={disabled}
        label="Дополненное описание при помощи ИИ"
        field={AiOptionStatus.SupplementedDescription}
        state={aiOptionStatus === AiOptionStatus.SupplementedDescription}
        onToggle={toggleSupplementedDescription}
      />
    </Stack>
  );
};
