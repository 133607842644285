import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

interface SlideshowImageProps {
  image: ProfilePhotoModel;
  isActiveSlide: boolean;
  slideProgress: number;
}

export const SlideshowImage: FC<SlideshowImageProps> = ({ image, isActiveSlide, slideProgress }) => {
  const scale = useMemo(() => 1 + slideProgress / 2000, [slideProgress]);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.only('mobile'));

  const mode = isMobile ? 'cover' : 'contain';

  return (
    <VpImage
      src={image.url}
      hash={image.hash}
      mode={mode}
      animateAppearance={false}
      sx={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        inset: 0,
        zIndex: isActiveSlide ? 0 : -1,
      }}
      imageProps={{
        sx: { willChange: 'transform' },
        style: { transform: `scale(${scale})` },
      }}
    />
  );
};
