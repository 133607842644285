import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import InformationFilled from '@vp/assets/icons/InformationFilled.svg?react';
import { ViewModel } from '@vp/common/ui/ViewModel';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { AppNotificationService } from '@vp/notification/AppNotificationService';

@injectable()
export class ProfilePageHeaderViewModel extends ViewModel {
  readonly adShown: Signal<boolean> = signal(false);

  constructor(
    private readonly appMenuService: AppMenuService,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  toggleMenu = (): void => {
    this.appMenuService.toggle();
  };

  showAd = (): void => {
    this.adShown.value = true;
  };

  closeAd = (): void => {
    this.adShown.value = false;
  };

  shareProfile = async (): Promise<void> => {
    try {
      const href = location.href;
      await navigator.clipboard.writeText(href);
      this.showSuccessNotification();
    } catch {
      this.showErrorNotification();
    }
  };

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'primary',
      message: 'Ссылка скопирована',
      Icon: InformationFilled,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  }

  private showErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Не удалось скопировать ссылку. Попробуйте сделать это вручную',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  }
}
