import { Typography } from '@mui/material/styles/createTypography';
import { styled, SxProps, Theme } from '@mui/system';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { VpMultilineTextInput, VpMultilineTextInputProps } from '@vp/common/ui/component/form/VpMultilineTextInput';
import { VpTextInput, VpTextInputProps } from '@vp/common/ui/component/form/VpTextInput';

export type VpTextFieldProps = {
  id: string;
  label?: string;
  error?: string | boolean;
  caption?: string;
  captionPosition?: 'static' | 'absolute';
  fullWidth?: boolean;
  variant?: VpTextInputProps['variant'];
  status?: VpTextInputProps['status'];
  disabled?: boolean;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  slotProps?: {
    input?: VpTextInputProps;
    multilineInput?: VpMultilineTextInputProps;
  };
};

const VpTextFieldRoot = styled('div', { name: 'VpTextField' })<Pick<VpTextFieldProps, 'fullWidth'>>(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  variants: [{ props: { fullWidth: true }, style: { width: '100%' } }],
}));

const VpTextFieldLabel = styled('label', {
  name: 'VpTextField',
  slot: 'label',
})<Pick<VpTextFieldProps, 'variant'>>(({ theme }) => ({
  color: theme.palette.text.primary,
  WebkitTapHighlightColor: 'transparent',
  '&:active': {
    backgroundColor: 'initial',
  },
  variants: [
    {
      props: { variant: 'x-small' },
      style: (theme.typography as Typography)?.formLabelSmall,
    },
    {
      props: { variant: 'small' },
      style: (theme.typography as Typography)?.formLabelSmall,
    },
    {
      props: { variant: 'medium' },
      style: (theme.typography as Typography)?.formLabelMedium,
    },
    {
      props: { variant: 'large' },
      style: (theme.typography as Typography)?.formLabelMedium,
    },
  ],
}));

const VpTextFieldCaption = styled('span')<Pick<VpTextFieldProps, 'variant' | 'status' | 'captionPosition'>>(({ theme }) => ({
  color: theme.palette.text.disabled,
  variants: [
    {
      props: { variant: 'x-small' },
      style: (theme.typography as Typography)?.caption1Regular,
    },
    {
      props: { variant: 'small' },
      style: (theme.typography as Typography)?.caption1Regular,
    },
    {
      props: { variant: 'medium' },
      style: (theme.typography as Typography)?.body2Regular,
    },
    {
      props: { variant: 'large' },
      style: (theme.typography as Typography)?.body2Regular,
    },

    {
      props: { status: 'success' },
      style: {
        color: theme.palette.success[500],
      },
    },
    {
      props: { status: 'danger' },
      style: {
        color: theme.palette.danger[500],
      },
    },

    {
      props: { captionPosition: 'absolute' },
      style: {
        width: '100%',
        position: 'absolute',
        top: `calc(100% + ${theme.spacing(0.75)})`,
      },
    },
  ],
}));

export const VpTextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, VpTextFieldProps>((props, ref) => {
  const status = resolveStatus(props);
  const disabled = resolveDisabled(props);
  const variant = resolveVariant(props);
  const { id, label, fullWidth, caption, error, slotProps, sx, captionPosition = 'static', multiline = false } = props;
  const inputProps: VpTextInputProps = { id, ...slotProps?.input, variant, status, disabled };
  const multilineInputProps: VpMultilineTextInputProps = {
    id,
    ...slotProps?.multilineInput,
    variant,
    status,
    disabled,
  };

  const inputRef = useRef<HTMLInputElement>(null!);
  const multilineInputRef = useRef<HTMLTextAreaElement>(null!);

  useImperativeHandle(ref, () => (multiline ? multilineInputRef.current : inputRef.current));

  return (
    <VpTextFieldRoot fullWidth={fullWidth} sx={sx}>
      {label && (
        <VpTextFieldLabel variant={variant} htmlFor={id}>
          {label}
        </VpTextFieldLabel>
      )}

      {multiline && <VpMultilineTextInput {...multilineInputProps} ref={multilineInputRef} />}
      {!multiline && <VpTextInput {...inputProps} ref={inputRef} />}

      {caption && !error && (
        <VpTextFieldCaption variant={variant} status={status}>
          {caption}
        </VpTextFieldCaption>
      )}

      {typeof error === 'string' && (
        <VpTextFieldCaption captionPosition={captionPosition} variant={variant} status="danger">
          {error}
        </VpTextFieldCaption>
      )}
    </VpTextFieldRoot>
  );
});

VpTextField.displayName = 'VpTextField';

function resolveStatus(props: VpTextFieldProps): VpTextInputProps['status'] {
  if (props.error) {
    return 'danger';
  }

  if (props.multiline) {
    return props.slotProps?.multilineInput?.status || props.status;
  }

  return props.slotProps?.input?.status || props.status;
}

function resolveDisabled(props: VpTextFieldProps): boolean {
  const inputDisabled = props.multiline ? props.slotProps?.multilineInput?.disabled : props.slotProps?.input?.disabled;

  if (inputDisabled != null) {
    return inputDisabled;
  }

  if (props.disabled != null) {
    return props.disabled;
  }

  return false;
}

function resolveVariant(props: VpTextFieldProps): NonNullable<VpTextInputProps['variant']> {
  const inputVariant = props.multiline ? props.slotProps?.multilineInput?.variant : props.slotProps?.input?.variant;

  if (inputVariant != null) {
    return inputVariant;
  }

  return props.variant || 'large';
}
