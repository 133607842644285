import { InternalStandardProps as StandardProps } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';
import { styled, SxProps, Theme } from '@mui/system';
import { forwardRef, HTMLAttributes, MutableRefObject, useCallback, useLayoutEffect, useRef } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

export type VpMultilineTextInputProps = {
  id?: string;
  variant?: 'large' | 'medium' | 'small' | 'x-small';
  status?: 'success' | 'danger';
  disabled?: boolean;
  placeholder?: string;
  registerReturn?: UseFormRegisterReturn;
  maxHeight?: number | string;
  sx?: SxProps<Theme>;
} & StandardProps<HTMLAttributes<HTMLTextAreaElement>>;

const VpMultilineTextInputRoot = styled('textarea', { name: 'VpMultilineTextInput' })<
  Pick<VpMultilineTextInputProps, 'variant' | 'disabled' | 'status'>
>(({ theme }) => ({
  width: '100%',
  minWidth: 0,
  outline: 'none',
  resize: 'none',
  borderRadius: 0,
  border: '1px solid',
  borderColor: theme.palette.transparency.alpha16,
  transition: 'border-color 0.25s ease-in-out',
  WebkitTapHighlightColor: 'transparent',
  '&:active': {
    backgroundColor: 'initial',
  },
  '&:hover': {
    borderColor: theme.palette.transparency.alpha48,
  },
  '&:focus': {
    borderColor: theme.palette.primary[500],
  },
  background: 'transparent',
  color: theme.palette.text.primary,
  '&::placeholder': {
    color: theme.palette.transparency.alpha48,
  },
  '&:-webkit-autofill': {
    WebkitBoxShadow: 'none',
    WebkitTextFillColor: theme.palette.primary['500'],
    backgroundClip: 'text',
  },
  variants: [
    {
      props: { variant: 'x-small' },
      style: {
        minHeight: '86px',
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        ...(theme.typography as Typography)?.formPlaceholderXSmall,
      },
    },
    {
      props: { variant: 'small' },
      style: {
        minHeight: '100px',
        padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)} ${theme.spacing(1.25)} ${theme.spacing(1.75)}`,
        ...(theme.typography as Typography)?.formPlaceholderSmall,
      },
    },
    {
      props: { variant: 'medium' },
      style: {
        minHeight: '120px',
        padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(2)}`,
        ...(theme.typography as Typography)?.formPlaceholderMedium,
      },
    },
    {
      props: { variant: 'large' },
      style: {
        minHeight: '128px',
        padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`,
        ...(theme.typography as Typography)?.formPlaceholderMedium,
      },
    },

    {
      props: { disabled: true },
      style: {
        opacity: 0.32,
      },
    },
    {
      props: { status: 'danger' },
      style: {
        borderColor: theme.palette.danger[500],
      },
    },
    {
      props: { status: 'success' },
      style: {
        borderColor: theme.palette.success[500],
      },
    },
  ],
}));

export const VpMultilineTextInput = forwardRef<HTMLTextAreaElement, VpMultilineTextInputProps>((props, ref) => {
  const { id, variant, status, disabled, placeholder, registerReturn, maxHeight, sx, ...rest } = props;
  const overflow = maxHeight ? 'scroll' : 'hidden';
  const textareaRef = useRef<HTMLTextAreaElement>(null) as MutableRefObject<HTMLTextAreaElement>;

  const setRef = useCallback(
    (element: HTMLTextAreaElement) => {
      textareaRef.current = element;

      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }

      registerReturn?.ref(element);
    },
    [ref, registerReturn],
  );

  useLayoutEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const onInput = (): void => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    onInput();
    textarea.addEventListener('input', onInput);

    return (): void => textarea.removeEventListener('input', onInput);
  }, []);

  return (
    <VpMultilineTextInputRoot
      {...rest}
      id={id}
      variant={variant}
      status={status}
      disabled={disabled}
      placeholder={placeholder}
      style={{ maxHeight, overflow }}
      sx={sx}
      {...registerReturn}
      ref={setRef}
    />
  );
});

VpMultilineTextInput.displayName = 'VpMultilineTextInput';
