import { Typography } from '@mui/material';
import { FC } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface HonorManagerEditHeaderProps {
  close: () => void;
}

export const HonorManagerEditHeader: FC<HonorManagerEditHeaderProps> = ({ close }) => {
  return (
    <VpHeader
      sx={theme => ({
        position: 'static',
        background: theme.palette.background[300],
        px: { tablet: 4 },
        pt: { tablet: 2 },
        pb: { mobile: 0.5, tablet: 2 },
      })}
    >
      <VpHeaderColumn align="left">
        <VpIconButton Icon={CloseIcon} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 1 } })} onClick={close} />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          Править
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
