import { useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpOverlayPage } from '@vp/common/ui/component/VpOverlayPage';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerUpload } from '@vp/manager/gallery/ui/upload/GalleryManagerUpload';

export interface GalleryManagerUploadModalProps {
  files: File[];
  open: boolean;
  close: () => void;
}

export const GalleryManagerUploadModal: FC<GalleryManagerUploadModalProps> = ({ files, open, close }) => {
  const section = useSearchSection<GalleryManagerSection>('s', GalleryManagerSection.Photos);

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  return (
    <VpOverlayPage width={isDesktop ? '53%' : 1} open={open}>
      <GalleryManagerUpload section={section} files={files} close={close} />
    </VpOverlayPage>
  );
};
