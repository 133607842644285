import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import Logo from '@vp/assets/icons/Logo.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export type ProfileActivationDescriptionProps = {
  instructionText: string;
};

export const ProfileActivationDescription: FC<ProfileActivationDescriptionProps> = ({ instructionText }) => {
  return (
    <Stack gap={1.5} alignItems="center">
      <VpIcon glowing Icon={Logo} size={130} />

      <Typography
        variant="h2"
        color="text.secondary"
        align="center"
        sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
      >
        Активация профиля
      </Typography>

      <Typography variant="body1Regular" color="text.disabled" textAlign="center">
        {instructionText}
      </Typography>
    </Stack>
  );
};
