import { Box } from '@mui/material';
import { forwardRef } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { VpPlayer } from '@vp/common/ui/player/VpPlayer';
import { ProfileVideoModel } from '@vp/profile/core/model/ProfileVideoModel';

export interface VideoViewerContentProps {
  video: ProfileVideoModel;
  close: () => void;
}

export const VideoViewerContent = forwardRef<HTMLElement, VideoViewerContentProps>(({ video, close }, ref) => {
  return (
    <Box
      ref={ref}
      autoFocus
      tabIndex={0}
      width={1}
      position="relative"
      sx={({ palette }) => ({ overflowY: 'hidden', outline: 'none', backgroundColor: palette.background.default })}
    >
      <VpIconButton
        Icon={CloseIcon}
        sx={theme => ({
          p: 1,
          position: 'fixed',
          top: { mobile: 0, tablet: theme.spacing(1) },
          left: { mobile: 0, tablet: theme.spacing(3) },
          zIndex: theme.zIndex.mediaControls,
        })}
        onClick={close}
      />

      <VpMediaGradientOverlay key={video.id} opacity={0.7}>
        <VpPlayer url={video.url} thumbnail={video.thumbnail} thumbnailHash={video.thumbnailHash} sx={{ height: '100dvh' }} />
      </VpMediaGradientOverlay>
    </Box>
  );
});

VideoViewerContent.displayName = 'VideoViewerContent';
