import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

const Text = {
  mobile:
    'Соберите и сохраните память \nо своих близких для поколений. Проживайте заветные воспоминания \nс помощью фотографий, видео и историй при каждом сканировании наших мемориалов с QR-кодом.',
  desktop:
    'Соберите и сохраните память о своих близких для поколений. Проживайте заветные воспоминания \nс помощью фотографий, видео и историй при каждом сканировании наших мемориалов с QR-кодом.',
};

export const LandingDescription: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const text = isMobile ? Text.mobile : Text.desktop;

  return (
    <Typography component="p" variant="body1Regular" color="text.disabled" display="inline-block" textAlign="center" whiteSpace="pre-line">
      {text}
    </Typography>
  );
};
