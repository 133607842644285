import { Typography, useTheme } from '@mui/material';
import { memo } from 'react';

import ChevronLeftIcon from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export type SlideshowGalleryHeaderProps = {
  profileName: string;
  onClose: () => void;
};

export const SlideshowGalleryHeader = memo<SlideshowGalleryHeaderProps>(({ profileName, onClose }) => {
  const { spacing } = useTheme();

  return (
    <VpHeader
      sx={theme => ({
        position: 'sticky',
        background: theme.palette.background[300],
        px: { mobile: 2, tablet: 4 },
        py: 2,
      })}
    >
      <VpHeaderColumn align="left">
        <VpIconButton
          Icon={ChevronLeftIcon}
          onClick={onClose}
          sx={theme => ({
            padding: 1,
            color: theme.palette.primary.main,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: spacing(-2),
              right: spacing(-3),
              bottom: spacing(-2),
              left: spacing(-2),
              background: 'transparent',
            },
          })}
        />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary" textAlign="center">
          {profileName}
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
});

SlideshowGalleryHeader.displayName = 'SlideshowGalleryHeader';
