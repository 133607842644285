import { FC } from 'react';

import { VpTab, VpTabs } from '@vp/common/ui/component/VpTabs';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';

const Tabs: VpTab[] = [
  { id: GalleryManagerSection.Photos, name: 'Фото', url: `.?s=${GalleryManagerSection.Photos}` },
  { id: GalleryManagerSection.Videos, name: 'Видео', url: `.?s=${GalleryManagerSection.Videos}` },
];

export const GalleryManagerMediaTabs: FC = () => {
  const section = useSearchSection('s', 'photos');
  return <VpTabs tabs={Tabs} activeTabId={section} sx={{ px: { mobile: 3, tablet: 7 } }} />;
};
