import { FC } from 'react';

import { AuthPageContainer } from '@vp/auth/ui/AuthPageContainer';
import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { RegisterForm } from '@vp/auth/ui/register/RegisterForm';

export const RegisterPage: FC = () => {
  return (
    <AuthPageContainer>
      <AuthPageDescription title="Регистрация" description="Ваша электронная почта будет использоваться для входа в аккаунт." />
      <RegisterForm />
    </AuthPageContainer>
  );
};
