import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';

interface EventsManagerFormDescriptionProps {
  index: number;
  disableControls: boolean;
}

export const EventsManagerFormDescription: FC<EventsManagerFormDescriptionProps> = ({ index, disableControls }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EventsFormValues>();

  const descriptionError = errors?.events?.[index]?.description;

  return (
    <VpTextField
      fullWidth
      id={`events.${index}.description`}
      label="Описание"
      disabled={disableControls}
      error={!!descriptionError}
      multiline
      slotProps={{
        multilineInput: {
          registerReturn: register(`events.${index}.description`, {
            required: true,
            minLength: 4,
            maxLength: 1000,
          }),
          placeholder:
            'Введите описание события из жизни человека, включая его ключевые моменты, влияние на его жизнь и наследие, которое оно оставило.',
        },
      }}
    />
  );
};
