import { MutableRefObject, useCallback, useLayoutEffect } from 'react';

export const useRectOnResize = (ref: MutableRefObject<HTMLElement | null>, handleRectChange: (rect: DOMRect) => void): (() => void) => {
  const onResize = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      handleRectChange(rect);
    }
  }, [handleRectChange, ref]);

  useLayoutEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return (): void => window.removeEventListener('resize', onResize);
  }, [handleRectChange, onResize, ref]);

  return onResize;
};
