import { Box, useTheme } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

interface EventsManagerFormImageContentProps {
  image: EventsManagerRecordModel['image'];
  id: string;
}

const Gradient = 'linear-gradient(133.62deg, #E5E5E5 -18.03%, rgba(229, 229, 229, 0.5) 52.31%, rgba(229, 229, 229, 0) 91.87%)';

export const EventsManagerFormImageContent: FC<EventsManagerFormImageContentProps> = ({ id, image }) => {
  const { palette } = useTheme();
  const backgroundColor = palette.background.default;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      borderRadius="50%"
      sx={{ inset: 0, background: Gradient }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" width={80} height={80} borderRadius="50%" sx={{ backgroundColor }}>
        <Box width={74} height={74} borderRadius="50%" overflow="hidden">
          <AnimatePresence mode="wait">
            <VpFade sx={{ width: 1, height: 1 }} key={`${image.url}.${id}`} duration={0.25}>
              {image.url && (
                <VpMediaGradientOverlay gradient="rgba(0, 0, 0, 0.4)" sx={{ width: 1, height: 1 }}>
                  <VpImage src={image.url} hash={image.hash} width="100%" height="100%" />
                </VpMediaGradientOverlay>
              )}
              {!image.url && <Box sx={{ width: 1, height: 1, backgroundColor: 'background.200' }} />}
            </VpFade>
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  );
};
