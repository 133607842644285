import { FC } from 'react';

import TrashCanIcon from '@vp/assets/icons/TrashCan.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface EventsManagerFormRemoveButtonProps {
  disabled: boolean;
  onRemove: () => void;
}

export const EventsManagerFormRemoveButton: FC<EventsManagerFormRemoveButtonProps> = ({ onRemove, disabled }) => {
  return (
    <VpIconButton
      disabled={disabled}
      Icon={TrashCanIcon}
      onClick={onRemove}
      sx={theme => ({
        p: 1,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(-1),
          right: theme.spacing(-1),
          bottom: theme.spacing(-1),
          left: theme.spacing(-1),
          background: 'transparent',
        },
        '& path': { fill: theme.palette.danger[500] },
      })}
    />
  );
};
