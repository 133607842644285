import { computed, effect, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';
import { UseFormReturn } from 'react-hook-form';

import { Success } from '@vp/common/core/OperationResult';
import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { RefineBiographyDto } from '@vp/manager/biography/core/dto/RefineBiographyDto';
import { UpdateBiographyDto } from '@vp/manager/biography/core/dto/UpdateBiographyDto';
import { BiographyManagerPort } from '@vp/manager/biography/core/interface/BiographyManagerPort';
import { EditModalFormValues } from '@vp/manager/biography/ui/edit/BiographyManagerEditContent';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export enum AiOptionStatus {
  SpellingCheck = 'SpellingCheck',
  SupplementedDescription = 'SupplementedDescription',
  None = 'None',
}

export interface BiographyFormValues {
  name: string;
  datebirth: string;
  datedeath: string;
  biography: string;
}

export interface BiographyManagerViewModelProps {
  methods: UseFormReturn<BiographyFormValues>;
}

@injectable()
export class BiographyManagerViewModel extends ViewModel<BiographyManagerViewModelProps> implements ViewModelInit, ViewModelDispose {
  readonly profile: Signal<ProfileModel> = computed(() => this.toViewProfile());
  readonly generatedBiography: Signal<string | null> = signal(null);
  readonly loading: Signal<boolean> = signal(false);
  readonly editModalShown: Signal<boolean> = signal(false);
  readonly aiOptionStatus: Signal<AiOptionStatus> = signal(AiOptionStatus.None);

  private controller?: AbortController;
  private disposeEffect?: () => void;

  constructor(
    private readonly notificationService: AppNotificationService,
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly dateFormatter: DateFormatter,
    private readonly biographyManagerPort: BiographyManagerPort,
  ) {
    super();
  }

  init(): void {
    this.resetFormOnProfileChange();
  }

  dispose(): void {
    this.controller?.abort();
    this.disposeEffect?.();
  }

  openEditModel = (): void => {
    this.editModalShown.value = true;
  };

  closeEditModal = (): void => {
    this.aiOptionStatus.value = AiOptionStatus.None;
    this.editModalShown.value = false;
  };

  changeAiOptionStatus = (status: AiOptionStatus): void => {
    this.aiOptionStatus.value = status;
  };

  toFormValues = (profile: ProfileModel): BiographyFormValues => {
    return {
      name: profile.name,
      datebirth: profile.birthDate,
      datedeath: profile.deathDate,
      biography: profile.bio,
    };
  };

  submit = async (values: BiographyFormValues): Promise<void> => {
    this.loading.value = true;

    if (this.aiOptionStatus.value != AiOptionStatus.None) {
      await this.refineBiography(values.biography);
    } else {
      await this.updateBiography(values);
    }

    this.loading.value = false;
  };

  onSaveSuggestedBiography = (values: EditModalFormValues): void => {
    this.props.value?.methods.setValue('biography', values.suggestedText, { shouldDirty: true });
    this.closeEditModal();
  };

  private updateBiography = async (values: BiographyFormValues): Promise<void> => {
    this.controller = new AbortController();

    if (await this.biographyManagerPort.update(this.toUpdateDto(values), this.controller)) {
      this.showSuccessNotification();
    } else {
      this.showErrorNotification();
    }
  };

  private async refineBiography(biography: BiographyFormValues['biography']): Promise<void> {
    this.controller = new AbortController();

    const result = await this.biographyManagerPort.refine(this.toRefineDto(biography), this.controller);

    if (result instanceof Success) {
      this.generatedBiography.value = result.value;
      this.openEditModel();
    } else {
      this.showErrorNotification();
    }
  }

  private toUpdateDto(values: BiographyFormValues): UpdateBiographyDto {
    return new UpdateBiographyDto({
      profileId: this.profile.value.id,
      name: values.name,
      dateOfBirth: values.datebirth,
      dateOfDeath: values.datedeath,
      biography: values.biography,
    });
  }

  private toRefineDto(biography: BiographyFormValues['biography']): RefineBiographyDto {
    return new RefineBiographyDto({
      biography: biography,
      profileId: this.profile.value.id,
      grammarOnly: this.aiOptionStatus.value === AiOptionStatus.SpellingCheck,
    });
  }

  private toViewProfile(): ProfileModel {
    const profile = this.profileManagerPort.active.value!;
    const birthDate = this.dateFormatter.toLocaleDate(profile.birthDate);
    const deathDate = this.dateFormatter.toLocaleDate(profile.deathDate);
    return { ...profile, birthDate, deathDate };
  }

  private resetFormOnProfileChange(): void {
    this.disposeEffect = effect(() => {
      const formValues = this.toFormValues(this.profile.value);
      this.props.value?.methods.reset(formValues);
    });
  }

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Биография была загружена в профиль',
      secondaryMessage: 'Вы можете отредактировать её в любой момент',
    });
  }

  private showErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: 'Не удалось отредактировать биографию',
    });
  }
}
