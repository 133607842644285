import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeft from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export const GalleryManagerPageHeader: FC = () => {
  return (
    <VpHeader
      sx={theme => ({
        position: 'static',
        background: theme.palette.background[300],
        px: { tablet: 4 },
        pt: { tablet: 2 },
        pb: { mobile: 0.5, tablet: 2 },
      })}
    >
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeft} sx={theme => ({ [theme.breakpoints.up('desktop')]: { p: 1 } })} to=".." />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          Галерея
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right">
        {/*<VpButton text="Выбрать" status="primary" variant="soft" size="x-small" sx={{ mr: 3 }} />*/}
      </VpHeaderColumn>
    </VpHeader>
  );
};
