import { Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowState, SlideshowStatus } from '@vp/slideshow/core/interface/SlideshowState';

@injectable()
export class SignalsSlideshowState implements SlideshowState {
  readonly slideshowStatus: Signal<SlideshowStatus> = signal('closed');
  readonly images: Signal<ProfilePhotoModel[]> = signal([]);
  readonly audio: Signal<Blob> = signal(new Blob());

  setSlideshowStatus(status: SlideshowStatus): void {
    this.slideshowStatus.value = status;
  }

  setImages(images: ProfilePhotoModel[]): void {
    this.images.value = images;
  }

  setAudio(audio: Blob): void {
    this.audio.value = audio;
  }
}
