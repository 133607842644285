import { Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import WarningAltGradient from '@vp/assets/icons/WarningAltGradient.svg?react';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export interface VpFallbackProps {
  title: string;
  text?: string;
  showRefresh?: boolean;
}

export const VpFallback: FC<VpFallbackProps> = ({ title, text, showRefresh }) => {
  showRefresh ??= false;
  const onRefresh = useCallback(() => window.location.reload(), []);

  return (
    <VpFade sx={{ width: 1, height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Stack width={1} maxWidth={440} px={3} justifyContent="center" alignItems="center">
        <VpIcon Icon={WarningAltGradient} size={80} sx={{ mb: 2 }} glowing />

        <Typography
          variant="h3"
          color="text.secondary"
          align="center"
          sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
          gutterBottom
        >
          {title}
        </Typography>
        {text && (
          <Typography variant="body1Regular" color="text.secondary" align="center" mb={4} whiteSpace="pre-line">
            {text}
          </Typography>
        )}
        {showRefresh && <VpFancyButton text="Обновить" onClick={onRefresh} />}
      </Stack>
    </VpFade>
  );
};
