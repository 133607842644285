import { FC, useCallback } from 'react';

import CloseFilled from '@vp/assets/icons/CloseFilled.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { MediaGridItem, VpMediaGrid, VpMediaGridProps } from '@vp/common/ui/component/VpMediaGrid';

export interface GalleryManagerUploadGridProps {
  uploading: boolean;
  items: MediaGridItem[];
  onRemove: (item: MediaGridItem) => void;
}

export const GalleryManagerUploadGrid: FC<GalleryManagerUploadGridProps> = ({ uploading, items, onRemove }) => {
  const itemDecorator = useCallback<NonNullable<VpMediaGridProps['itemDecorator']>>(
    item => {
      return (
        <VpMediaGradientOverlay gradient="rgba(0, 0, 0, 0.25)" sx={{ position: 'absolute', inset: -2, pointerEvents: 'none' }}>
          <VpIconButton
            Icon={CloseFilled}
            size={20}
            sx={{ position: 'absolute', top: 0, right: 0, p: 0.5, zIndex: 1, color: 'transparency.alpha48' }}
            disabled={uploading}
            onClick={() => onRemove(item)}
          />
        </VpMediaGradientOverlay>
      );
    },
    [onRemove, uploading],
  );

  return (
    <VpMediaGrid
      items={items}
      itemDecorator={itemDecorator}
      sx={{ pt: 1, pb: 3, px: { mobile: 3, tablet: 7 }, py: 2, overflow: 'visible' }}
    />
  );
};
