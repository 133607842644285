import { Stack } from '@mui/material';
import { FC, memo } from 'react';

import { VpButton } from '@vp/common/ui/component/VpButton';

export type ThemingManagerControlsProps = {
  loading: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const ThemingManagerControls: FC<ThemingManagerControlsProps> = memo(({ loading, onSave, onCancel }) => {
  return (
    <Stack
      sx={{
        py: 1,
        gap: 1,
        width: 1,
        flexDirection: 'row',
        pb: { mobile: 2, tablet: 4 },
        px: { mobile: 3, tablet: 7 },
        backgroundColor: 'background.300',
      }}
    >
      <VpButton text="Отменить" status="primary" variant="soft" size="small" sx={{ flex: 1 }} onClick={onCancel} />
      <VpButton text="Сохранить" status="primary" variant="solid" size="small" loading={loading} sx={{ flex: 1 }} onClick={onSave} />
    </Stack>
  );
});

ThemingManagerControls.displayName = ThemingManagerControls.name;
