export const customSizes = {
  smallImageHeight: {
    mobile: 286,
    tablet: 400,
  },
  fullImageHeight: {
    mobile: 436,
    tablet: 866,
  },
  profileSettingsMenuOffset: {
    mobile: 38,
  },
};
