import { Stack } from '@mui/material';
import { FC } from 'react';

import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';
import { ProfileEventDescription } from '@vp/profile/ui/events/ProfileEventDescription';
import { ProfileEventImage } from '@vp/profile/ui/events/ProfileEventImage';
import { useImageSizes } from '@vp/profile/ui/hook/useImageSizes';

export interface ProfileEventProps {
  event: ProfileEventModel;
  top: number;
}

export const ProfileEvent: FC<ProfileEventProps> = ({ event, top }) => {
  const { smallImageHeight } = useImageSizes();

  return (
    <Stack position="relative" flexDirection="row" width={1} gap={5}>
      <ProfileEventImage
        event={event}
        sx={theme => ({
          position: 'sticky',
          top: {
            mobile: `calc(${smallImageHeight}px + ${theme.spacing(1)})`,
            desktop: `calc(${top}px + ${theme.spacing(1)})`,
          },
        })}
      />
      <ProfileEventDescription event={event} />
    </Stack>
  );
};
