import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AuthPageContainer } from '@vp/auth/ui/AuthPageContainer';
import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { LoginForm } from '@vp/auth/ui/login/LoginForm';

export const LoginPage: FC = () => {
  return (
    <AuthPageContainer>
      <AuthPageDescription title="Войти в Аккаунт" description={'Введите вашу электронную почту \nи пароль'} />
      <LoginForm />

      <Stack direction="row" gap={1}>
        <Typography variant="body1Regular" color="text.disabled">
          Нет аккаунта?
        </Typography>

        <Typography
          component={Link}
          to="/auth/register"
          variant="body1Bold"
          color="text.secondary"
          sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
        >
          Зарегистрироваться
        </Typography>
      </Stack>
    </AuthPageContainer>
  );
};
