import { Stack, SvgIcon, Typography } from '@mui/material';
import { memo } from 'react';

import Email from '@vp/assets/icons/Email.svg?react';

export type AppMenuUserProps = {
  email: string;
};

export const AppMenuUser = memo<AppMenuUserProps>(({ email }) => {
  return (
    <Stack height={20} direction="row" gap={0.5} alignItems="center" sx={theme => ({ color: theme.palette.text.primary })}>
      <SvgIcon sx={{ fontSize: 14 }}>
        <Email />
      </SvgIcon>

      <Typography variant="caption1Regular" maxWidth={250} noWrap>
        {email}
      </Typography>
    </Stack>
  );
});

AppMenuUser.displayName = 'AppMenuUser';
