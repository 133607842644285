import { IconButton } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';

import View from '@vp/assets/icons/View.svg?react';
import ViewOff from '@vp/assets/icons/ViewOff.svg?react';

export interface ViewPasswordIconProps {
  showPassword: boolean;
  onClick: () => void;
}

export const ViewPasswordIcon: FC<ViewPasswordIconProps> = ({ showPassword, onClick }) => {
  const handleMouseDownPassword = useCallback((event: MouseEvent) => event.preventDefault(), []);

  return (
    <IconButton
      sx={({ spacing }) => ({
        fontSize: 'var(--vp-text-input-end-adornment-size)',
        color: 'var(--vp-text-input-end-adornment-color)',
        p: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          inset: `-${spacing(2)} -${spacing(1.5)} -${spacing(2)} -${spacing(1.5)}`,
        },
      })}
      onClick={onClick}
      onMouseDown={handleMouseDownPassword}
    >
      {showPassword ? <View /> : <ViewOff />}
    </IconButton>
  );
};
