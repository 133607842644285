import { Box, Stack, useTheme } from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface SubmitRecordFormProps {
  handleFormSubmit: (record: FormData) => Promise<ProfileHonorNotificationStatus>;
  submittingRecord: boolean;
  submitButtonText: string;
}

interface FormData {
  name: string;
  text: string;
}

const TextValidationOptions: RegisterOptions<FormData> = {
  required: { value: true, message: '' },
  minLength: {
    value: 8,
    message: 'Текст слишком короткий',
  },
  maxLength: {
    value: 1000,
    message: 'Текст слишком длинный',
  },
};

const NameValidationOptions: RegisterOptions<FormData> = {
  required: true,
  minLength: 4,
  maxLength: 50,
};

export const SubmitRecordForm: FC<SubmitRecordFormProps> = ({ handleFormSubmit, submittingRecord, submitButtonText }) => {
  const { register, handleSubmit, formState } = useForm<FormData>({ defaultValues: { name: '', text: '' }, mode: 'onTouched' });
  const { spacing, palette } = useTheme();

  const textInputRef = useRef<HTMLTextAreaElement>(null!);

  const handleFocus = useCallback(() => {
    if (textInputRef?.current) {
      textInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [textInputRef]);

  return (
    <Stack component="form" width="100%" gap={3} onSubmit={handleSubmit(handleFormSubmit)}>
      <VpTextField
        fullWidth
        id="form-name"
        label="Ваше имя"
        error={!!formState.errors.name}
        disabled={submittingRecord}
        slotProps={{
          input: {
            registerReturn: register('name', NameValidationOptions),
            placeholder: 'Например, Александр Иванов',
          },
        }}
      />

      <VpTextField
        fullWidth
        multiline
        id="form-text"
        label="Запись"
        error={formState.errors.text?.message || !!formState.errors.text}
        disabled={submittingRecord}
        ref={textInputRef}
        slotProps={{
          multilineInput: {
            placeholder: 'Например, воспоминания, слова благодарности или просто мысли об ушедшем',
            registerReturn: register('text', TextValidationOptions),
            onFocus: handleFocus,
          },
        }}
      />

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          textAlign: 'center',
          backgroundColor: palette.background.default,
          padding: spacing(2),
          width: 1,
        }}
      >
        <VpFancyButton disabled={!formState.isValid} loading={submittingRecord} type="submit" text={submitButtonText} />
      </Box>
    </Stack>
  );
};
