import { useIsPresent } from 'motion/react';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchSection<S = string>(name: string, defaultSection: S = '' as S): S {
  const location = useLocation();
  const prevSection = useRef<S>(defaultSection);
  const isPresent = useIsPresent();

  return useMemo(() => {
    if (!isPresent) return prevSection.current;

    const search = new URLSearchParams(location.search);
    const section = search.get(name) ?? defaultSection;
    prevSection.current = section as S;

    return section as S;
  }, [defaultSection, isPresent, location.search, name]);
}
