import { Box, Stack } from '@mui/material';
import { AnimatePresence, LayoutGroup, motion } from 'motion/react';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpLimitInfo } from '@vp/common/ui/component/VpLimitInfo';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerUploadGrid } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadGrid';
import { GalleryManagerUploadHeader } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadHeader';
import { GalleryManagerUploadProgress } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadProgress';
import { GalleryManagerUploadViewModel } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadViewModel';

export interface GalleryManagerUploadProps {
  files: File[];
  section: GalleryManagerSection;
  close: () => void;
}

const PhotoLimitText = 'Максимальное количество фото — 50.';
const VideoLimitText = 'Максимальное количество видео — 5.\nМаксимальный размер файла — 200 МБ.';

const ButtonGradient =
  'linear-gradient(90.82deg, rgba(229, 229, 229, 0.35) -43.91%, rgba(229, 229, 229, 0.15) 48.8%, rgba(229, 229, 229, 0) 150.27%)';

export const GalleryManagerUpload: FC<GalleryManagerUploadProps> = props => {
  const viewModel = useViewModel(GalleryManagerUploadViewModel, props);
  const uploading = viewModel.uploadSnapshot.value?.state === 'uploading';
  const limitText = props.section === GalleryManagerSection.Photos ? PhotoLimitText : VideoLimitText;

  return (
    <Stack width={1} height="100dvh">
      <GalleryManagerUploadHeader onClose={props.close} />

      <Box flexGrow={1} overflow="scroll">
        <LayoutGroup>
          <GalleryManagerUploadGrid uploading={uploading} items={viewModel.items.value} onRemove={viewModel.remove} />

          {viewModel.showLimits.value && (
            <motion.div layout>
              <VpLimitInfo text={limitText} profileId={viewModel.profile.value?.id ?? ''} />
            </motion.div>
          )}
        </LayoutGroup>
      </Box>

      <Box sx={{ width: 1, pt: 1, pb: { mobile: 2, tablet: 4 }, px: { mobile: 3, tablet: 7 }, backgroundColor: 'background.300' }}>
        <AnimatePresence mode="wait">
          <VpFade key={uploading.toString()} duration={0.25} sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
            {!uploading && <VpFancyButton text="Добавить в профиль" sx={{ background: ButtonGradient }} onClick={viewModel.upload} />}

            {uploading && (
              <GalleryManagerUploadProgress progress={viewModel.uploadSnapshot.value!.progress} onCancel={viewModel.cancelUpload} />
            )}
          </VpFade>
        </AnimatePresence>
      </Box>
    </Stack>
  );
};
