import { Box, Fade, useTheme } from '@mui/material';
import { FC, Fragment, useCallback, useEffect } from 'react';

import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { useControlsVisible } from '@vp/common/ui/hook/useControlsVisible';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { SlideshowControls } from '@vp/slideshow/ui/component/control/SlideshowControls';
import { SlideshowGalleryModal } from '@vp/slideshow/ui/component/gallery/SlideshowGalleryModal';
import { SlideshowImage } from '@vp/slideshow/ui/component/SlideshowImage';
import { SlideshowIntro } from '@vp/slideshow/ui/component/SlideshowIntro';
import { useVisibilityPause } from '@vp/slideshow/ui/hook/useVisibilityPause';
import { SlideshowViewModel } from '@vp/slideshow/ui/SlideshowViewModel';

export const SlideshowContent: FC = () => {
  const viewModel = useViewModel(SlideshowViewModel);
  const { transitions } = useTheme();

  const closeSlideshow = useCallback(() => viewModel.closeSlideshow('closed'), [viewModel]);
  const skipSlideshow = useCallback(() => viewModel.closeSlideshow('skipped'), [viewModel]);

  const { controlsVisible, toggleControlsVisibility, setInteractionLock, heartbeat } = useControlsVisible();

  useVisibilityPause(viewModel.pause);

  useEffect(() => {
    heartbeat();
    setInteractionLock(viewModel.isPaused.value);
  }, [viewModel.isPaused.value, setInteractionLock, heartbeat]);

  return (
    <Fragment>
      <Box sx={{ height: '100%', width: '100%', overflow: 'hidden' }} onClick={toggleControlsVisibility}>
        <VpMediaGradientOverlay sx={{ height: '100%', width: '100%' }}>
          {viewModel.images.value.map((image, index) => (
            <SlideshowImage
              key={image.id}
              image={image}
              slideProgress={viewModel.currentSlideProgress.value}
              isActiveSlide={index === viewModel.currentSlide.value}
            />
          ))}
        </VpMediaGradientOverlay>

        <SlideshowControls
          progress={viewModel.progress.value}
          isPaused={viewModel.isPaused.value}
          togglePause={viewModel.togglePause}
          controlsVisible={controlsVisible}
          openGallery={viewModel.openGallery}
          toggleAudioMuted={viewModel.toggleMuted}
          isAudioMuted={viewModel.muted.value}
          close={closeSlideshow}
        />
      </Box>

      <SlideshowGalleryModal
        profileName={viewModel.profileName.value}
        open={viewModel.galleryShown.value}
        close={viewModel.closeGallery}
        images={viewModel.images.value}
      />

      <Fade in={viewModel.introShown.value} timeout={transitions.duration.modalFadeDuration} easing="ease-in-out" unmountOnExit>
        <Box sx={theme => ({ position: 'absolute', inset: 0, zIndex: theme.zIndex.slideshowIntro })}>
          <SlideshowIntro
            skipSlideshow={skipSlideshow}
            startSlideshow={viewModel.startSlideshow}
            image={viewModel.introImage.value}
            imageScale={viewModel.introImageScale.value}
            buttonText={viewModel.introButtonText.value}
            name={viewModel.profileName.value}
            birthDeathDates={viewModel.birthDeathDates.value}
          />
        </Box>
      </Fade>
    </Fragment>
  );
};
