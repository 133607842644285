import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import ChatIcon from '@vp/assets/icons/Chat.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export const NoRecordsMessage: FC = () => {
  return (
    <Stack sx={{ width: 1, maxWidth: 440 }} py={8} alignItems="center">
      <VpIcon Icon={ChatIcon} size={40} glowing />

      <Typography mt={1} textAlign="center" color="text.disabled" variant="body1Regular">
        Оставьте запись в память об ушедшем близком. Ваша запись будет отправлена на модерацию и появится в галерее почестей после одобрения
        владельцем профиля.
      </Typography>
    </Stack>
  );
};
