import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileActivationAuth } from '@vp/profile/ui/activation/ProfileActivationAuth';
import { ProfileActivationDescription } from '@vp/profile/ui/activation/ProfileActivationDescription';
import { ProfileActivationViewModel } from '@vp/profile/ui/activation/ProfileActivationViewModel';

export const ProfileActivationPage: FC = () => {
  const viewModel = useViewModel(ProfileActivationViewModel);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <VpFade
      sx={theme => ({
        width: 1,
        height: '100dvh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.only('mobile')]: { px: 3, pt: 7.5 },
      })}
    >
      <Stack sx={{ width: 1, height: 1, maxWidth: 440, justifyContent: 'space-between' }}>
        {!isMobile && <Box />}

        <Stack width={1} gap={4} alignItems="center">
          <ProfileActivationDescription instructionText={viewModel.instructionText.value} />

          {viewModel.canActivateProfile.value && (
            <VpFancyButton text="Активировать" loading={viewModel.loading.value} onClick={viewModel.activate} />
          )}

          {!viewModel.canActivateProfile.value && (
            <ProfileActivationAuth
              loading={viewModel.loading.value}
              onLoginClick={viewModel.loginToActivate}
              onRegisterClick={viewModel.registerToActivate}
            />
          )}
        </Stack>

        {viewModel.canActivateProfile.value && (
          <Typography mb={3.5} variant="caption1Regular" color="text.secondaryDisabled" textAlign="center">
            {`Профиль будет привязан к вашему аккаунту ${viewModel.email.value}`}
          </Typography>
        )}

        {!viewModel.canActivateProfile.value && <Box />}
      </Stack>
    </VpFade>
  );
};
