import { useMediaQuery, useTheme } from '@mui/material';
import { FC, Fragment } from 'react';

import Menu from '@vp/assets/icons/Menu.svg?react';
import QrCode from '@vp/assets/icons/QrCode.svg?react';
import Share from '@vp/assets/icons/Share.svg?react';
import { VpHeader } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileAdModal } from '@vp/profile/ui/header/ProfileAdModal';
import { ProfilePageHeaderViewModel } from '@vp/profile/ui/header/ProfilePageHeaderViewModel';
import { ProfileThemeProvider } from '@vp/profile/ui/ProfileThemeProvider';

export const ProfilePageHeader: FC = () => {
  const viewModel = useViewModel(ProfilePageHeaderViewModel);

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  return (
    <Fragment>
      <VpHeader sx={{ pointerEvents: 'none', px: { mobile: 1, tablet: 3, desktop: 5 }, pt: { mobile: 1, desktop: 3 } }}>
        {isDesktop && (
          <ProfileThemeProvider>
            <VpIconButton
              glowing={false}
              Icon={Menu}
              sx={{ mr: 'auto', [breakpoints.up('desktop')]: { p: 2 } }}
              onClick={viewModel.toggleMenu}
            />
          </ProfileThemeProvider>
        )}

        {!isDesktop && <VpIconButton glowing Icon={Menu} sx={{ mr: 'auto' }} onClick={viewModel.toggleMenu} />}

        <VpIconButton glowing Icon={QrCode} sx={{ pr: 1.5, [breakpoints.up('desktop')]: { p: 2 } }} onClick={viewModel.showAd} />
        <VpIconButton glowing Icon={Share} sx={{ pl: 1.5, [breakpoints.up('desktop')]: { p: 2 } }} onClick={viewModel.shareProfile} />
      </VpHeader>

      <ProfileAdModal open={viewModel.adShown.value} close={viewModel.closeAd} />
    </Fragment>
  );
};
