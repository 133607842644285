import { Stack } from '@mui/material';
import { FC, Fragment } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { BiographyManagerAIControls } from '@vp/manager/biography/ui/BiographyManagerAIControls';
import { AiOptionStatus, BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';
import { BiographyManagerFormDate } from '@vp/manager/biography/ui/form/BiographyManagerFormDate';
import { BiographyManagerFormDescription } from '@vp/manager/biography/ui/form/BiographyManagerFormDescription';
import { BiographyManagerFormName } from '@vp/manager/biography/ui/form/BiographyManagerFormName';
import { BiographyManagerFormSubmitButton } from '@vp/manager/biography/ui/form/BiographyManagerFormSubmitButton';
import { BiographyManagerFormText } from '@vp/manager/biography/ui/form/BiographyManagerFormText';

export interface BiographyManagerBodyProps {
  changeAiOptionStatus: (status: AiOptionStatus) => void;
  submit: () => void;
  methods: UseFormReturn<BiographyFormValues>;
  aiOptionStatus: AiOptionStatus;
  loading: boolean;
}

export const BiographyManagerBody: FC<BiographyManagerBodyProps> = ({ methods, aiOptionStatus, loading, changeAiOptionStatus, submit }) => {
  return (
    <Fragment>
      <Stack overflow="scroll" flexGrow={1} px={{ mobile: 3, tablet: 7 }}>
        <FormProvider {...methods}>
          <Stack gap={3} mb={3}>
            <BiographyManagerFormName disabled={loading} />
            <BiographyManagerFormDate disabled={loading} field="datebirth" label="Дата Рождения" />
            <BiographyManagerFormDate disabled={loading} field="datedeath" label="Дата Смерти" />
            <BiographyManagerFormText />
            <BiographyManagerFormDescription disabled={loading} />
          </Stack>
        </FormProvider>

        <BiographyManagerAIControls aiOptionStatus={aiOptionStatus} disabled={loading} changeAiOptionStatus={changeAiOptionStatus} />
      </Stack>

      <Stack alignItems="center" width={1} pt={2} pb={{ mobile: 2, tablet: 4 }} px={{ mobile: 3, tablet: 7 }}>
        <BiographyManagerFormSubmitButton
          onClick={submit}
          aiOptionStatus={aiOptionStatus}
          loading={loading}
          disabled={!methods.formState.isValid || !methods.formState.isDirty}
        />
      </Stack>
    </Fragment>
  );
};
