import { Box, Fade, Modal, useMediaQuery, useTheme } from '@mui/material';
import { ModalOwnProps } from '@mui/material/Modal/Modal';
import { FC, useCallback } from 'react';

import { VpSystemThemeProvider } from '@vp/common/ui/component/VpSystemThemeProvider';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { ProfileImageCarousel } from '@vp/profile/ui/gallery/image/ProfileImageCarousel';
import { ProfileImageScroller } from '@vp/profile/ui/gallery/image/ProfileImageScroller';

export interface ProfileImageViewer {
  open: boolean;
  selectedPhotoId: string;
  photos: ProfilePhotoModel[];
  close: () => void;
}

export const ProfileImageViewer: FC<ProfileImageViewer> = ({ selectedPhotoId, photos, open, close }) => {
  const { transitions, breakpoints } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  const onClose = useCallback<NonNullable<ModalOwnProps['onClose']>>((_, reason) => reason === 'escapeKeyDown' && close?.(), [close]);

  return (
    <Modal open={open} onClose={onClose} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box width={1} height={1}>
          <VpSystemThemeProvider>
            {!isDesktop && <ProfileImageScroller {...{ selectedPhotoId, photos, close }} />}
            {isDesktop && <ProfileImageCarousel {...{ selectedPhotoId, photos, close }} />}
          </VpSystemThemeProvider>
        </Box>
      </Fade>
    </Modal>
  );
};
