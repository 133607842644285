import { Box, Drawer, Stack } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import Logo from '@vp/assets/icons/Logo.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { AppMenuList } from '@vp/menu/ui/AppMenuList';
import { AppMenuUser } from '@vp/menu/ui/AppMenuUser';
import { AppMenuViewModel } from '@vp/menu/ui/AppMenuViewModel';

export const AppMenu: FC = () => {
  const viewModel = useViewModel(AppMenuViewModel);

  return (
    <Drawer
      open={viewModel.open.value}
      PaperProps={{
        sx: theme => ({
          width: { mobile: 1, desktop: '53%' },
          background: { mobile: theme.palette.background[200], desktop: theme.palette.background[300] },
        }),
      }}
      slotProps={{ backdrop: { sx: { background: 'transparent' } } }}
      onClose={viewModel.close}
    >
      <Stack alignItems="center">
        <VpIconButton
          Icon={Close}
          sx={theme => ({
            mt: 1,
            position: 'absolute',
            top: { mobile: 0, tablet: theme.spacing(1) },
            left: { mobile: 0, tablet: theme.spacing(3) },
            [theme.breakpoints.up('desktop')]: { p: 1 },
          })}
          onClick={viewModel.close}
        />

        <Stack
          style={{ justifyContent: viewModel.authenticated.value ? 'space-between' : 'center' }}
          sx={{ width: 200, height: 200, mb: 2, py: 2, alignItems: 'center' }}
        >
          {viewModel.authenticated.value && <Box height={20} />}

          <Logo style={{ width: 110, height: 86 }} />

          {viewModel.authenticated.value && <AppMenuUser email={viewModel.email.value} />}
        </Stack>

        <AppMenuList authenticated={viewModel.authenticated.value} items={viewModel.items.value} onItemClick={viewModel.onItemClick} />
      </Stack>
    </Drawer>
  );
};
