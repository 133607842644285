import { Box, useTheme } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';

interface AvatarManagerImageProps {
  src: string;
  hash: string;
  onCloseClick: () => void;
}

export const AvatarManagerImage: FC<AvatarManagerImageProps> = props => {
  const { customSizes } = useTheme();

  return (
    <Box position="relative">
      <VpMediaGradientOverlay>
        <VpImage sx={{ width: 1, height: customSizes.fullImageHeight.mobile }} src={props.src} hash={props.hash} alt="profile image" />

        <VpIconButton
          sx={theme => ({
            padding: 0.5,
            position: 'absolute',
            zIndex: 1,
            top: 16,
            right: 16,
            backgroundColor: theme.palette.primary.alpha24,
            color: theme.palette.background[300],
          })}
          Icon={Close}
          size={24}
          onClick={props.onCloseClick}
        />
      </VpMediaGradientOverlay>
    </Box>
  );
};
