import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { motion, MotionStyle, Variants } from 'motion/react';
import { FC, PropsWithChildren, useMemo } from 'react';

const pageVariants: Variants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export interface VpFadeProps extends PropsWithChildren {
  duration?: number;
  style?: MotionStyle;
  sx?: SxProps<Theme>;
}

export const VpFade: FC<VpFadeProps> = ({ children, duration = 0.5, style, sx }) => {
  const transition = useMemo(() => ({ type: 'tween', ease: 'easeInOut', duration }), [duration]);

  return (
    <Box
      component={motion.div}
      sx={sx}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={transition}
      style={style}
    >
      {children}
    </Box>
  );
};
