import { Stack } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ViewPasswordIcon } from '@vp/auth/ui/register/ViewPasswordIcon';
import { RestorePasswordFormData, RestorePasswordViewModel } from '@vp/auth/ui/restore-password/RestorePasswordViewModel';
import { PasswordValidationOptions } from '@vp/auth/ui/validation/PasswordValidationOptions';
import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

export const RestorePasswordForm: FC = () => {
  const viewModel = useViewModel(RestorePasswordViewModel);

  const { register, handleSubmit, formState, watch } = useForm<RestorePasswordFormData>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onTouched',
  });

  const password = watch('password');
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = useCallback(() => setShowPassword(state => !state), []);

  return (
    <Stack component="form" width={1} alignItems="center" gap={3} onSubmit={handleSubmit(viewModel.restorePassword)}>
      <VpTextField
        fullWidth
        id="form-password"
        label="Введите ваш пароль"
        disabled={viewModel.loading.value}
        error={formState.errors.password?.message}
        slotProps={{
          input: {
            type: passwordInputType,
            placeholder: 'Введите ваш пароль',
            registerReturn: register('password', PasswordValidationOptions),
            endAdornment: <ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />,
          },
        }}
      />

      <VpTextField
        fullWidth
        id="form-password-confirmation"
        label="Подтвердите Пароль"
        sx={{ mb: 1 }}
        disabled={viewModel.loading.value}
        error={formState.errors.passwordConfirmation?.message}
        slotProps={{
          input: {
            type: passwordInputType,
            placeholder: 'Подтвердите ваш пароль',
            registerReturn: register('passwordConfirmation', {
              required: 'Пожалуйста, подтвердите пароль',
              validate: value => value === password || 'Пароли не совпадают',
            }),
            endAdornment: <ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />,
          },
        }}
      />

      <VpFancyButton text="Изменить" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
