import { Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useForm, RegisterOptions } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoginFormData, LoginViewModel } from '@vp/auth/ui/login/LoginViewModel';
import { ViewPasswordIcon } from '@vp/auth/ui/register/ViewPasswordIcon';
import { EmailValidationOptions } from '@vp/auth/ui/validation/EmailValidationOptions';
import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

const PasswordValidationOptions: RegisterOptions<LoginFormData> = {
  required: true,
  maxLength: 24,
};

export const LoginForm: FC = () => {
  const viewModel = useViewModel(LoginViewModel);

  const { register, handleSubmit, formState } = useForm<LoginFormData>({
    defaultValues: { email: '', password: '' },
    mode: 'onTouched',
  });

  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = useCallback(() => setShowPassword(state => !state), []);

  return (
    <Stack component="form" width="100%" alignItems="center" mb={2} onSubmit={handleSubmit(viewModel.login)}>
      <VpTextField
        fullWidth
        id="form-email"
        label="Почта"
        sx={{ mb: 3 }}
        disabled={viewModel.loading.value}
        error={formState.errors.email?.message}
        slotProps={{
          input: {
            type: 'email',
            placeholder: 'example@mail.com',
            registerReturn: register('email', EmailValidationOptions),
          },
        }}
      />

      <VpTextField
        fullWidth
        id="form-password"
        label="Пароль"
        sx={{ mb: 2 }}
        disabled={viewModel.loading.value}
        error={!!formState.errors.password}
        slotProps={{
          input: {
            type: passwordInputType,
            placeholder: 'Введите ваш пароль',
            registerReturn: register('password', PasswordValidationOptions),
            endAdornment: <ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />,
          },
        }}
      />

      <Typography
        component={Link}
        to="/auth/forgot-password"
        variant="body2Medium"
        color="primary.500"
        sx={{
          mb: 4,
          alignSelf: 'flex-start',
          textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)',
        }}
      >
        Забыли пароль?
      </Typography>

      <VpFancyButton text="Войти" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
