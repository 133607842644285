import { ButtonBase, styled } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import { AnimatePresence } from 'motion/react';
import { FC } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { VpSpinner } from '@vp/common/ui/component/spinner/VpSpinner';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { FileUploadProps, VpHiddenFileInput } from '@vp/common/ui/component/VpHiddenFileInput';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';

type BaseButtonProps = Parameters<typeof ButtonBase>[0];

export type VpButtonProps = BaseButtonProps & {
  to?: To;
  text: string;
  loading?: boolean;
  fileUpload?: FileUploadProps;
  status: 'primary' | 'overlay';
  variant: 'solid' | 'soft' | 'outlined';
  size: 'large' | 'medium' | 'small' | 'x-small';
};

const VpButtonRoot = styled(ButtonBase)<BaseButtonProps & Required<Pick<VpButtonProps, 'size' | 'status' | 'variant'>>>(({ theme }) => ({
  transition: `opacity 0.25s ease-in-out`,
  '&.Mui-disabled': { opacity: 0.32 },
  variants: [
    {
      props: { size: 'x-small' },
      style: {
        padding: `${theme.spacing(0.875)} ${theme.spacing(1.5)}`,
        ...theme.typography.buttonSmall,
      },
    },
    {
      props: { size: 'small' },
      style: {
        padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
        ...theme.typography.buttonMedium,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        padding: `${theme.spacing(1.625)} ${theme.spacing(2.5)}`,
        ...theme.typography.buttonLarge,
      },
    },
    {
      props: { size: 'large' },
      style: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        ...theme.typography.buttonGiant,
      },
    },

    {
      props: { status: 'primary', variant: 'solid' },
      style: {
        backgroundColor: theme.palette.primary[500],
        '--vp-text-color': theme.palette.background[300],
        color: 'var(--vp-text-color)',
      },
    },
    {
      props: { status: 'primary', variant: 'soft' },
      style: {
        backgroundColor: theme.palette.primary.alpha16,
        '--vp-text-color': theme.palette.text.secondary,
        color: 'var(--vp-text-color)',
      },
    },
    {
      props: { status: 'primary', variant: 'outlined' },
      style: {
        backgroundColor: 'transparent',
        '--vp-text-color': theme.palette.primary[500],
        color: 'var(--vp-text-color)',
        border: '2px solid',
        borderImageSource: getGradient(theme),
        borderImageSlice: 1,
      },
    },

    {
      props: { status: 'overlay', variant: 'solid' },
      style: {
        backgroundColor: theme.palette.background[300],
        '--vp-text-color': theme.palette.text.primary,
        color: 'var(--vp-text-color)',
      },
    },
    {
      props: { status: 'overlay', variant: 'soft' },
      style: {
        backgroundColor: theme.palette.transparency.alpha8,
        '--vp-text-color': theme.palette.primary[500],
        color: 'var(--vp-text-color)',
      },
    },
    {
      props: { status: 'overlay', variant: 'outlined' },
      style: {
        backgroundColor: 'transparent',
        '--vp-text-color': theme.palette.text.primary,
        color: 'var(--vp-text-color)',
        border: '1px solid',
        borderColor: theme.palette.transparency.alpha24,
      },
    },
  ],
}));

const SizeToNumber = {
  'x-small': 18,
  small: 20,
  medium: 22,
  large: 24,
};

export const VpButton: FC<VpButtonProps> = props => {
  const { text, size, status, variant, loading = false, fileUpload, sx, ...other } = props;

  const delayedLoading = useFalsyStateDelay(loading, 250);
  const fadeKey = `${delayedLoading},${text}`;

  return (
    <VpButtonRoot
      size={size}
      status={status}
      variant={variant}
      sx={sx}
      {...other}
      {...(delayedLoading ? { disabled: true } : {})}
      LinkComponent={other.to ? RouterLink : other.LinkComponent}
      {...(fileUpload ? { component: 'label' } : {})}
    >
      <AnimatePresence mode="wait">
        <VpFade key={fadeKey} duration={0.25} style={{ height: `${SizeToNumber[size]}px` }}>
          {!delayedLoading && text}
          {delayedLoading && <VpSpinner size={SizeToNumber[size]} />}
          {fileUpload && <VpHiddenFileInput {...fileUpload} />}
        </VpFade>
      </AnimatePresence>
    </VpButtonRoot>
  );
};

function getGradient(theme: Theme): string {
  const color = theme.palette.primary[500] as string;
  return `linear-gradient(93.17deg, ${alpha(color, 0.7)} 1.37%, ${alpha(color, 0.5)} 50.9%, ${alpha(color, 0.1)} 105.12%)`;
}
