import { Box, ImageList, ImageListItem } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowGalleryHeader } from '@vp/slideshow/ui/component/gallery/SlideshowGalleryHeader';

interface SlideshowGalleryContentProps {
  close: (goToImageId?: string) => void;
  images: ProfilePhotoModel[];
  profileName: string;
}

export const SlideshowGalleryContent: FC<SlideshowGalleryContentProps> = ({ close, images, profileName }) => {
  const [lockInteractions, setLockInteractions] = useState(false);

  const handleClose = useCallback(
    (goToImageId?: string) => {
      if (!lockInteractions) {
        setLockInteractions(true);
        close(goToImageId);
      }
    },
    [close, lockInteractions],
  );

  const onClose = useCallback(() => handleClose(), [handleClose]);

  return (
    <Box sx={{ width: 1, pb: 2, textAlign: 'left', position: 'relative' }}>
      <SlideshowGalleryHeader profileName={profileName} onClose={onClose} />

      <ImageList
        cols={3}
        gap={8}
        sx={theme => ({ width: 1, maxWidth: 650, [theme.breakpoints.only('mobile')]: { px: 3 }, m: 0, mx: 'auto' })}
      >
        {images.map(image => (
          <ImageListItem onClick={() => handleClose(image.id)} sx={{ position: 'relative', pt: '133.65%' }} key={image.id}>
            <Box
              sx={theme => ({
                position: 'absolute',
                inset: 0,
                backgroundImage: `url(${image.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                [theme.breakpoints.up('desktop')]: {
                  cursor: 'pointer',
                },
              })}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
