import { Stack } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { LandingDescription } from '@vp/landing/LandingDescription';
import { LandingNavigation } from '@vp/landing/LandingNavigation';
import { LandingSlogan } from '@vp/landing/LandingSlogan';

export const LandingPage: FC = () => {
  return (
    <VpFade
      sx={theme => ({
        width: 1,
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.only('mobile')]: {
          px: 3,
          pb: 1,
          alignItems: 'flex-end',
        },
      })}
    >
      <Stack gap={6} alignItems="center" sx={{ maxWidth: 440, width: 1 }}>
        <Stack gap={2}>
          <LandingSlogan />
          <LandingDescription />
        </Stack>

        <LandingNavigation />
      </Stack>
    </VpFade>
  );
};
