import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';

import { VpButton } from '@vp/common/ui/component/VpButton';
import { FileUploadProps } from '@vp/common/ui/component/VpHiddenFileInput';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';

export interface GalleryManagerControlsProps {
  onFileUpload: (files: File[]) => void;
}

export const GalleryManagerControls: FC<GalleryManagerControlsProps> = ({ onFileUpload }) => {
  const section = useSearchSection('s', 'photos');
  const isPhotosSection = section === 'photos';

  const text = isPhotosSection ? 'Загрузить фото' : 'Загрузить видео';

  const fileUpload = useMemo<FileUploadProps>(() => {
    const accept = isPhotosSection ? 'image' : 'video';
    return { accept, multiple: true, onFileUpload };
  }, [onFileUpload, isPhotosSection]);

  return (
    <Stack width={1} pt={1} pb={{ mobile: 2, tablet: 4 }} px={{ mobile: 3, tablet: 7 }} gap={1} direction="row">
      {/*<VpButton text="Переставить" status="primary" variant="soft" size="small" sx={{ flex: 1 }} />*/}
      <VpButton text={text} status="primary" variant="solid" size="small" sx={{ flex: 1 }} fileUpload={fileUpload} />
    </Stack>
  );
};
