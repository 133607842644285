import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export type ImageSizes = {
  fullImageHeight: number;
  smallImageHeight: number;
};

export const useImageSizes = (): ImageSizes => {
  const { customSizes, breakpoints } = useTheme();
  const { fullImageHeight, smallImageHeight } = customSizes;
  const isMobile = useMediaQuery(breakpoints.only('mobile'));

  return useMemo(
    () =>
      isMobile
        ? { fullImageHeight: fullImageHeight.mobile, smallImageHeight: smallImageHeight.mobile }
        : { fullImageHeight: fullImageHeight.tablet, smallImageHeight: smallImageHeight.tablet },
    [fullImageHeight.mobile, fullImageHeight.tablet, isMobile, smallImageHeight.mobile, smallImageHeight.tablet],
  );
};
