import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFallback } from '@vp/common/ui/component/VpFallback';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileSettingsMenu } from '@vp/manager/profile/ui/settings/menu/ProfileSettingsMenu';
import { NoProfiles } from '@vp/manager/profile/ui/settings/NoProfiles';
import { ProfileSettingsImage } from '@vp/manager/profile/ui/settings/ProfileSettingsImage';
import { ProfileSettingsInfo } from '@vp/manager/profile/ui/settings/ProfileSettingsInfo';
import { ProfileSettingsPageHeader } from '@vp/manager/profile/ui/settings/ProfileSettingsPageHeader';
import { ProfileSettingsViewModel } from '@vp/manager/profile/ui/settings/ProfileSettingsViewModel';

export const ProfileSettingsPage: FC = () => {
  const viewModel = useViewModel(ProfileSettingsViewModel);

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  return (
    <VpFade key={viewModel.profile.value?.id}>
      <ProfileSettingsPageHeader noProfiles={viewModel.noProfiles.value} onMenuClick={viewModel.toggleMenu} />

      {viewModel.isError.value && (
        <VpFallback title="Что-то пошло не так" text="Вероятно, возникла техническая проблема или профиль не найден" />
      )}

      {!viewModel.isError.value && viewModel.noProfiles.value && <NoProfiles />}

      {viewModel.profile.value && (
        <Stack sx={theme => ({ width: 1, [theme.breakpoints.up('desktop')]: { pt: 10.25 } })}>
          {!isDesktop && <ProfileSettingsImage profile={viewModel.profile.value} />}
          <ProfileSettingsInfo profile={viewModel.profile.value} />

          <Stack width={1} gap={1.5} px={{ mobile: 2, tablet: 7 }}>
            <ProfileSettingsMenu items={viewModel.mainItems.value} />
            <ProfileSettingsMenu items={viewModel.additionalItems.value} />
          </Stack>
        </Stack>
      )}
    </VpFade>
  );
};
