import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileEvents } from '@vp/profile/ui/events/ProfileEvents';
import { ProfileGallery } from '@vp/profile/ui/gallery/ProfileGallery';
import { ProfilePageHeader } from '@vp/profile/ui/header/ProfilePageHeader';
import { ProfileHonor } from '@vp/profile/ui/honor/ProfileHonor';
import { ProfileInfo } from '@vp/profile/ui/navigation/ProfileInfo';
import { ProfileBio } from '@vp/profile/ui/ProfileBio';
import { ProfileDesktopImage } from '@vp/profile/ui/ProfileDesktopImage';
import { ProfileImage } from '@vp/profile/ui/ProfileImage';
import { ProfilePageNoise } from '@vp/profile/ui/ProfilePageNoise';
import { ProfileThemeProvider } from '@vp/profile/ui/ProfileThemeProvider';
import { ProfileSection, ProfileViewModel } from '@vp/profile/ui/ProfileViewModel';
import { SlideshowModal } from '@vp/profile/ui/SlideshowModal';

export const ProfilePage: FC = () => {
  useSearchSectionCheck('s', ProfileSection.Bio);

  const viewModel = useViewModel(ProfileViewModel);
  const section = useSearchSection<ProfileSection>('s', ProfileSection.Bio);

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  const profileInfoRef = useRef<HTMLDivElement>(null);
  const [profileSectionTop, setProfileSectionTop] = useState(0);

  const onResizeTrigger = useRectOnResize(
    profileInfoRef,
    useCallback(({ height }) => setProfileSectionTop(height), []),
  );

  useEffect(() => void (isDesktop && onResizeTrigger()), [isDesktop, onResizeTrigger]);

  return (
    <Fragment>
      <VpFade>
        <ProfilePageHeader />

        <ProfileThemeProvider>
          <Stack
            sx={{ backgroundColor: 'background.300', [breakpoints.up('desktop')]: { flexDirection: 'row', alignItems: 'flex-start' } }}
          >
            {!isDesktop && <ProfileImage image={viewModel.profile.value.image} />}

            <Stack sx={{ position: 'relative', [breakpoints.up('desktop')]: { width: '53%', height: '100dvh', overflowY: 'scroll' } }}>
              <ProfileInfo
                ref={profileInfoRef}
                name={viewModel.profile.value.name}
                birthDate={viewModel.profile.value.birthDate}
                deathDate={viewModel.profile.value.deathDate}
              />

              <AnimatePresence mode="wait">
                <VpFade key={section} duration={0.25}>
                  {section === ProfileSection.Bio && <ProfileBio profile={viewModel.profile.value} />}
                  {section === ProfileSection.Events && <ProfileEvents profile={viewModel.profile.value} top={profileSectionTop} />}
                  {section === ProfileSection.Gallery && <ProfileGallery profile={viewModel.profile.value} />}
                  {section === ProfileSection.Honor && <ProfileHonor profile={viewModel.profile.value} />}
                </VpFade>
              </AnimatePresence>

              <ProfilePageNoise />
            </Stack>

            {isDesktop && (
              <Box sx={{ position: 'relative', width: '47%' }}>
                <ProfileDesktopImage image={viewModel.profile.value.image} />
              </Box>
            )}
          </Stack>
        </ProfileThemeProvider>
      </VpFade>

      <SlideshowModal slideshowStatus={viewModel.slideshowStatus.value} />
    </Fragment>
  );
};
