import { Box } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { FC, Fragment } from 'react';

import { VpDialog } from '@vp/common/ui/component/VpDialog';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { AvatarImagePlaceholder } from '@vp/manager/avatar/ui/AvatarImagePlaceholder';
import { AvatarManagerHeader } from '@vp/manager/avatar/ui/AvatarManagerHeader';
import { AvatarManagerImage } from '@vp/manager/avatar/ui/AvatarManagerImage';
import { AvatarManagerViewModel } from '@vp/manager/avatar/ui/AvatarManagerViewModel';

export const AvatarManagerPage: FC = () => {
  const viewModel = useViewModel(AvatarManagerViewModel);
  const delayedRemoving = useFalsyStateDelay(viewModel.removing.value, 250);

  const showImage = !!viewModel.profile.value?.image.url;

  return (
    <Fragment>
      <VpFade sx={{ height: '100dvh', width: 1 }}>
        <AvatarManagerHeader />

        <Box
          sx={theme => ({
            [theme.breakpoints.up('tablet')]: {
              width: 1,
              height: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          })}
        >
          <AnimatePresence mode="wait">
            <VpFade
              duration={0.25}
              key={viewModel.profile.value!.image.url}
              sx={{ width: { mobile: 1, tablet: 391 }, height: { mobile: 'auto', tablet: 436 } }}
            >
              {showImage && (
                <AvatarManagerImage
                  src={viewModel.profile.value!.image.url}
                  hash={viewModel.profile.value!.image.hash}
                  onCloseClick={viewModel.onRemoveButtonClick}
                />
              )}

              {!showImage && <AvatarImagePlaceholder uploading={viewModel.uploading.value} onFileUpload={viewModel.onFileUpload} />}
            </VpFade>
          </AnimatePresence>
        </Box>
      </VpFade>

      <VpDialog
        open={viewModel.dialogShown.value}
        title="Удалить фото профиля"
        text="Вы уверены, что хотите удалить фото профиля? Это действие нельзя отменить."
        actionText="Удалить"
        cancelText="Отменить"
        maxWidth={450}
        actionLoading={delayedRemoving}
        cancelDisabled={delayedRemoving}
        onAction={viewModel.removeImage}
        onCancel={viewModel.closeDialog}
      />
    </Fragment>
  );
};
