import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { VpButton } from '@vp/common/ui/component/VpButton';
import { VpModal } from '@vp/common/ui/component/VpModal';

export interface VpDialogProps {
  open: boolean;
  title: string;
  text: string;
  actionText: string;
  cancelText: string;
  actionLoading?: boolean;
  cancelDisabled?: boolean;
  onAction: () => void;
  onCancel: () => void;
  maxWidth?: number | string;
}

export const VpDialog: FC<VpDialogProps> = props => {
  const { cancelDisabled = false, actionLoading = false, open, title, text, actionText, cancelText, onAction, onCancel } = props;

  return (
    <VpModal open={open} maxWidth={props.maxWidth} close={onCancel}>
      <Stack width={1} p={3} alignItems="center">
        <Typography variant="h4" color="text.secondary" textAlign="center" gutterBottom>
          {title}
        </Typography>

        <Typography variant="body1Regular" color="text.disabled" textAlign="center" mb={2}>
          {text}
        </Typography>

        <Stack width={1} direction="row" gap={1} alignItems="center">
          <VpButton
            text={cancelText}
            status="primary"
            variant="soft"
            size="small"
            sx={{ flex: 1 }}
            disabled={cancelDisabled}
            onClick={onCancel}
          />

          <VpButton
            text={actionText}
            status="primary"
            variant="solid"
            size="small"
            sx={{ flex: 1 }}
            loading={actionLoading}
            onClick={onAction}
          />
        </Stack>
      </Stack>
    </VpModal>
  );
};
