import { Stack } from '@mui/material';
import { AnimatePresence, motion } from 'motion/react';
import { FC } from 'react';

import { VpLimitInfo } from '@vp/common/ui/component/VpLimitInfo';
import { HonorManagerListItem } from '@vp/manager/honor/ui/component/list/HonorManagerListItem';
import { NoRecordsMessage } from '@vp/manager/honor/ui/component/NoRecordsMessage';
import { RequestState } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

interface HonorManagerListProps {
  records: ProfileHonorRecordModel[];
  profile: ProfileModel;
  actionButtonText: string;
  activeRequestState: RequestState;
  actionHandler: (record: ProfileHonorRecordModel) => void | Promise<void>;
  removeHandler: (id: string) => void;
}

export const HonorManagerList: FC<HonorManagerListProps> = ({
  records,
  actionHandler,
  removeHandler,
  profile,
  actionButtonText,
  activeRequestState,
}) => {
  if (!records.length) {
    return <NoRecordsMessage />;
  }

  return (
    <Stack spacing={2}>
      <AnimatePresence mode="popLayout">
        {records.map(record => (
          <motion.div
            key={record.id}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.25 }}
            layout
          >
            <HonorManagerListItem
              removeHandler={removeHandler}
              actionHandler={actionHandler}
              disableInteractions={activeRequestState.inProgress}
              activeRequestState={activeRequestState}
              key={record.id}
              record={record}
              actionButtonText={actionButtonText}
            />
          </motion.div>
        ))}

        {!profile.premium && (
          <motion.div transition={{ duration: 0.25 }} layout>
            <VpLimitInfo sx={{ mb: 2.5 }} text="максимальное количество записей – 25" profileId={profile.id} />
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
};
