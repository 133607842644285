import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface VpOverlayPageProps extends PropsWithChildren {
  open: boolean;
  width?: string | number;
}

export const VpOverlayPage: FC<VpOverlayPageProps> = ({ open, width, children }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={open} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box
          width={width ?? 1}
          height="100dvh"
          autoFocus
          tabIndex={0}
          position="relative"
          sx={{ overflowY: 'scroll', outline: 'none', backgroundColor: 'background.300' }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
