import { Signal } from '@preact/signals-react';

import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

export type SlideshowStatus = 'open' | 'closed' | 'skipped';

export abstract class SlideshowState {
  abstract readonly slideshowStatus: Signal<SlideshowStatus>;
  abstract readonly images: Signal<ProfilePhotoModel[]>;
  abstract readonly audio: Signal<Blob>;

  abstract setSlideshowStatus(status: SlideshowStatus): void;
  abstract setImages(images: ProfilePhotoModel[]): void;
  abstract setAudio(audio: Blob): void;
}
