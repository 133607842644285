import { Box, Fade, Stack } from '@mui/material';
import { FC } from 'react';

import { CloseButton } from '@vp/slideshow/ui/component/control/CloseButton';
import { ShareButton } from '@vp/slideshow/ui/component/control/ShareButton';
import { SlideshowGalleryButton } from '@vp/slideshow/ui/component/control/SlideshowGalleryButton';
import { SlideshowPlaybackButton } from '@vp/slideshow/ui/component/control/SlideshowPlaybackButton';
import { VolumeButton } from '@vp/slideshow/ui/component/control/VolumeButton';

interface SlideshowControlsProps {
  progress: number;
  controlsVisible: boolean;
  isPaused: boolean;
  togglePause: () => void;
  openGallery: () => void;
  toggleAudioMuted: () => void;
  isAudioMuted: boolean;
  close: () => void;
}

export const SlideshowControls: FC<SlideshowControlsProps> = ({
  close,
  progress,
  isPaused,
  togglePause,
  controlsVisible,
  openGallery,
  toggleAudioMuted,
  isAudioMuted,
}) => {
  return (
    <Fade in={controlsVisible} timeout={500}>
      <Stack
        sx={theme => ({
          position: 'fixed',
          inset: 0,
          justifyContent: 'space-between',
          willChange: 'opacity',
          zIndex: theme.zIndex.mediaControls,
        })}
      >
        <Stack
          direction="row"
          sx={{
            width: 1,
            px: { mobile: 1, tablet: 7 },
            mt: { mobile: 1, desktop: 4 },
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row">
            <CloseButton close={close} />
            <VolumeButton muted={isAudioMuted} toggleAudioMuted={toggleAudioMuted} />
          </Stack>

          <ShareButton />
        </Stack>

        <Stack
          direction="row"
          sx={theme => ({
            width: 1,
            mb: { mobile: 3, tablet: 7 },
            px: { mobile: 3, tablet: 7 },
            justifyContent: 'space-between',
            zIndex: theme.zIndex.mediaControls,
          })}
        >
          <Box width={64} />
          <SlideshowPlaybackButton progress={progress} isPaused={isPaused} togglePause={togglePause} />
          <SlideshowGalleryButton openGallery={openGallery} />
        </Stack>
      </Stack>
    </Fade>
  );
};
