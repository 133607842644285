import { FC } from 'react';

import { AuthPageContainer } from '@vp/auth/ui/AuthPageContainer';
import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { RestorePasswordForm } from '@vp/auth/ui/restore-password/RestorePasswordForm';

export const RestorePasswordPage: FC = () => {
  return (
    <AuthPageContainer>
      <AuthPageDescription title="Восстановление Доступа" description="Введите новый пароль" />
      <RestorePasswordForm />
    </AuthPageContainer>
  );
};
