import { createTheme } from '@mui/material';

import { breakpoints } from '@vp/theme/breakpoints';
import { customSizes } from '@vp/theme/customSizes';
import { lightNoise } from '@vp/theme/lightNoise';
import { primaryColorCssProperty, systemPalette } from '@vp/theme/palette';
import { shadows } from '@vp/theme/shadows';
import { transitions } from '@vp/theme/transitions';
import { fontFaces, typography } from '@vp/theme/typography';
import { zIndex } from '@vp/theme/zIndex';

export const theme = createTheme({
  customSizes,
  palette: systemPalette,
  breakpoints,
  shadows,
  typography,
  spacing: 8,
  transitions,
  zIndex,
  lightNoise,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fontFaces}
        ${primaryColorCssProperty}

        body, html { width: 100%; height: 100%; margin: 0; padding: 0; box-sizing: border-box; }

        a, a:focus, a:active {
          text-decoration: none;
          color: inherit;
          outline: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
        }
      `,
    },
  },
});
