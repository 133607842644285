import { Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';

export type ProfileActivationAuthProps = {
  loading: boolean;
  onLoginClick: () => void;
  onRegisterClick: () => void;
};

export const ProfileActivationAuth: FC<ProfileActivationAuthProps> = memo(({ loading, onRegisterClick, onLoginClick }) => {
  return (
    <Stack gap={3} mt={4}>
      <VpFancyButton text="Войти в аккаунт" loading={loading} onClick={onLoginClick} />

      <Stack direction="row" gap={1}>
        <Typography variant="body1Regular" color="text.disabled">
          Нет аккаунта?
        </Typography>

        <Typography
          variant="body1Bold"
          color="text.secondary"
          sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
          onClick={onRegisterClick}
        >
          Зарегистрироваться
        </Typography>
      </Stack>
    </Stack>
  );
});

ProfileActivationAuth.displayName = 'ProfileActivationAuth';
