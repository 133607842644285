import { Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ForgotPasswordFormData, ForgotPasswordViewModel } from '@vp/auth/ui/forgot-password/ForgotPasswordViewModel';
import { EmailValidationOptions } from '@vp/auth/ui/validation/EmailValidationOptions';
import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

export const ForgotPasswordForm: FC = () => {
  const viewModel = useViewModel(ForgotPasswordViewModel);

  const { register, handleSubmit, formState } = useForm<ForgotPasswordFormData>({
    defaultValues: { email: '' },
    mode: 'onTouched',
  });

  return (
    <Stack component="form" gap={4} width={1} alignItems="center" onSubmit={handleSubmit(viewModel.sendPasswordResetLink)}>
      <VpTextField
        fullWidth
        id="form-email"
        label="Почта"
        sx={{ mb: 3 }}
        disabled={viewModel.loading.value}
        error={formState.errors.email?.message}
        slotProps={{
          input: {
            type: 'email',
            placeholder: 'example@mail.com',
            registerReturn: register('email', EmailValidationOptions),
          },
        }}
      />

      <VpFancyButton text="Восстановить доступ" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
