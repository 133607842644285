import { Box } from '@mui/material';
import { useMotionValue, useSpring } from 'motion/react';
import { FC, useEffect, useState } from 'react';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { useImageSizes } from '@vp/profile/ui/hook/useImageSizes';

export interface ProfileImageProps {
  image: ProfilePhotoModel;
}

export const ProfileImage: FC<ProfileImageProps> = ({ image }) => {
  const [position, setPosition] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const { fullImageHeight, smallImageHeight } = useImageSizes();

  const rawPosition = useMotionValue(0);
  const smoothPosition = useSpring(rawPosition, { damping: 50, stiffness: 300 });

  const gradient = `linear-gradient(180deg, rgba(0, 0, 0, ${multiplier * 0.18}) 0%, rgba(0, 0, 0, ${multiplier * 0.72}) 56.8%, rgba(0, 0, 0, ${multiplier * 0.9}) 100%)`;
  const heightDiff = fullImageHeight - smallImageHeight;

  useEffect(() => smoothPosition.on('change', setPosition), [smoothPosition]);

  useEffect(() => {
    const root = document.getElementById('root')!;

    const onScroll = (): void => {
      const top = root.scrollTop;
      rawPosition.set(Math.min(top / 2, heightDiff / 2));
      setMultiplier(Math.min(top / heightDiff, 1));
    };

    onScroll();

    root.addEventListener('scroll', onScroll);
    return (): void => root.removeEventListener('scroll', onScroll);
  }, [heightDiff, rawPosition]);

  return (
    <Box
      sx={theme => ({
        zIndex: theme.zIndex.galleryProfileImage,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        height: `${fullImageHeight}px`,
        position: 'sticky',
        top: `-${heightDiff}px`,
      })}
    >
      <VpImage src={image.url} hash={image.hash} width="100%" height="100%" position={`center ${position}px`} />
      <Box position="absolute" width={1} height={1} top="0" left="0" style={{ background: gradient }} />
    </Box>
  );
};
