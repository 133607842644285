import { Box } from '@mui/material';
import { motion } from 'motion/react';
import { FC, useRef, useState } from 'react';

import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';

export type VpProgressBarProps = {
  progress: number;
};

export const VpProgressBar: FC<VpProgressBarProps> = ({ progress }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const progressWidth = (containerWidth * Math.min(progress, 100)) / 100;

  useRectOnResize(containerRef, ({ width }) => setContainerWidth(width));

  return (
    <Box ref={containerRef} sx={{ width: 1, height: 6, backgroundColor: 'transparency.alpha16' }}>
      <Box
        component={motion.div}
        initial={{ width: 0 }}
        animate={{ width: progressWidth }}
        sx={{
          height: 1,
          backgroundColor: 'primary.500',
          boxShadow: '0px 0px 32px 0px rgba(255, 255, 255, 0.5), 0px 0px 128px 0px rgba(255, 255, 255, 0.5)',
        }}
      />
    </Box>
  );
};
