import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormNameProps {
  disabled: boolean;
}

export const BiographyManagerFormName: FC<BiographyManagerFormNameProps> = ({ disabled }) => {
  const { register, formState } = useFormContext<BiographyFormValues>();

  return (
    <VpTextField
      fullWidth
      id="form-name"
      label="Имя"
      disabled={disabled}
      error={!!formState.errors.name}
      slotProps={{
        input: {
          registerReturn: register('name', { required: true, minLength: 4, maxLength: 50 }),
          placeholder: 'Например, Александр Петров',
        },
      }}
    />
  );
};
