import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';

export const AuthPageContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <VpFade
      sx={theme => ({
        width: 1,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.only('mobile')]: { px: 3 },
      })}
    >
      <Stack sx={{ pt: 5, width: 1, maxWidth: 440, alignItems: 'center' }}>{children}</Stack>
    </VpFade>
  );
};
