import { injectable } from 'inversify';

import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowMediaLoader } from '@vp/slideshow/core/interface/SlideshowMediaLoader';
import { SlideshowState } from '@vp/slideshow/core/interface/SlideshowState';

@injectable()
export class ScheduleSlideshowUsecase {
  private readonly MinSlideshowSlides: number = 3;
  private readonly MaxSlideshowSlides: number = 15;
  private readonly MaxSlidesToLoadInCache: number = 20;

  constructor(
    private readonly slideshowMediaLoader: SlideshowMediaLoader,
    private readonly slideshowState: SlideshowState,
  ) {}

  async execute(profile: ProfileModel): Promise<void> {
    if (profile.photos.length < this.MinSlideshowSlides) {
      return this.slideshowState.setSlideshowStatus('skipped');
    }

    // todo: handle case if audio is not loaded (maybe just disable mute icon)
    const [successfulImages, audio] = await Promise.all([
      this.slideshowMediaLoader.loadImages(this.prepareProfileImagesToLoad(profile)),
      this.slideshowMediaLoader.loadAudio(profile.slideshowAudio),
    ]);

    if (successfulImages.length < this.MinSlideshowSlides) {
      return this.slideshowState.setSlideshowStatus('skipped');
    }

    this.slideshowState.setAudio(audio);
    this.slideshowState.setImages(this.createSlideshowImages(successfulImages, profile.image.id));
    this.slideshowState.setSlideshowStatus('open');
  }

  private createSlideshowImages(images: ProfilePhotoModel[], avatarId: string): ProfilePhotoModel[] {
    const avatar = images.find(image => image.id === avatarId);
    const slideshowImages = avatar ? [avatar, ...this.excludeAvatar(images, avatarId)] : images;

    return slideshowImages.slice(0, this.MaxSlideshowSlides);
  }

  private prepareProfileImagesToLoad(profile: ProfileModel): ProfilePhotoModel[] {
    const photosExcludingAvatar = this.excludeAvatar(profile.photos, profile.image.id);
    const shuffledPhotos = photosExcludingAvatar.sort(() => 0.5 - Math.random());
    const randomPhotos = shuffledPhotos.slice(0, this.MaxSlidesToLoadInCache);

    return [profile.image, ...randomPhotos];
  }

  private excludeAvatar(images: ProfilePhotoModel[], avatarId: string): ProfilePhotoModel[] {
    return images.filter(image => image.id !== avatarId);
  }
}
